import { createStore } from 'vuex'
import ModelUser from './user'
import ModelPost from './post'
import ModelArticle from './article'
import ModelAttention from './attention'
import ModelPlayer from './player'
import ModelWu from './wu'
import ModelFriend from './friend'
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: ModelUser,
    post: ModelPost,
    article: ModelArticle,
    attention: ModelAttention,
    player: ModelPlayer,
    wu: ModelWu,
    friend: ModelFriend,
  }
})
