<template>
    <PlayGround v-if="$store.state.wu.status === 'playing'" />
    <MatchGround v-if="$store.state.wu.status === 'matching'" />
    <ResultBoard v-if="$store.state.wu.loser != 'none'" />

    <div class="user-color"
        v-if="$store.state.wu.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.wu.a_id)">
        您为黑棋,黑棋先手
    </div>
    <div class="user-color"
        v-if="$store.state.wu.status === 'playing' && parseInt($store.state.user.id) === parseInt($store.state.wu.b_id)">
        您为白棋,白棋后手
    </div>
</template>

<script>
import { useStore } from 'vuex';
import PlayGround from '../五子棋/components/PlayGround.vue'
import MatchGround from './components/MatchGround.vue';
import { onMounted, onUnmounted } from 'vue';
import ResultBoard from '../五子棋/components/ResultBoard.vue'
export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard
    },
    setup() {
        const store = useStore();
        const socketUrl = `wss://scqblog.com/websocket/${store.state.user.token}/`;

        let socket = null;
        onMounted(() => {
            store.commit("updateOpponent", {
                username: "我的对手",
                photo: "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            })

            socket = new WebSocket(socketUrl);

            socket.onopen = () => {
                store.commit("updateSocket", socket);
            }

            socket.onmessage = msg => {
                const data = JSON.parse(msg.data);
                if (data.event === "start-matching") {
                    store.commit("updateOpponent", {
                        username: data.opponent_username,
                        photo: data.opponent_photo,
                    });
                    setTimeout(() => {
                        store.commit("updateStatus", "playing");
                    }, 200);
                    store.commit("updateGame", data.game);
                } else if (data.event === "move") {
                    const game = store.state.wu.gameObject;
                    const [chess0, chess1] = game.chesses;
                    if (data.a_direction_x !== null && data.a_direction_y != null) {
                        chess0.set_direction(data.a_direction_x, data.a_direction_y, 0);
                    }
                    if (data.b_direction_x !== null && data.b_direction_y != null) {
                        chess1.set_direction(data.b_direction_x, data.b_direction_y, 1);
                    }
                } else if (data.event === "result") {
                    store.commit("updateLoser", data.loser);
                }
            }

            socket.onclose = () => {
                console.log("开裂");
            }
        });

        onUnmounted(() => {
            socket.close();
            store.commit("updateStatus", "matching");
        })
    }
}
</script>

<style scoped>
div.user-color {
    text-align: center;
    color: rgb(216, 66, 66);
    font-size: 30px;
    font-weight: 600;
    user-select: none;
}
</style>
