import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'nprogress/nprogress.css';
import '@/assets/fonts/font.css';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';
import 'prismjs/components/prism-java';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-c';
import 'prismjs/components/prism-cpp';
import 'prismjs/components/prism-bash';
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
import { applyMouseMoveEffect } from './views/utils/mouseMoveEffect';
import VueEasyLightbox from 'vue-easy-lightbox'

import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
VMdEditor.use(vuepressTheme, {
    Prism,
    config: {
        toc: {
            includeLevel: [1, 2, 3, 4, 5],
        },
    },
    codeHighlightExtensionMap: {
        java: 'java',
        vue: 'html',
        python: 'python',
        c: 'c',
        cpp: 'cpp',
        sh: 'bash' // 添加Bash语言
    },
}).use(createEmojiPlugin())
    .use(createLineNumbertPlugin())
    .use(createHighlightLinesPlugin())
    .use(createTodoListPlugin())
    .use(createCopyCodePlugin())
    .use(createMermaidPlugin())
    .use(createKatexPlugin());

VMdPreview.use(vuepressTheme, {
    Prism,
    codeHighlightExtensionMap: {
        java: 'java',
        vue: 'html',
        python: 'python',
        c: 'c',
        cpp: 'cpp'
    },
}).use(createEmojiPlugin())
    .use(createLineNumbertPlugin())
    .use(createHighlightLinesPlugin())
    .use(createTodoListPlugin())
    .use(createCopyCodePlugin())
    .use(createMermaidPlugin())
    .use(createKatexPlugin());

const app = createApp(App);
app.directive('mouse-move-effect', {
    mounted(el) {
        applyMouseMoveEffect(el);
    }
});
app.use(store)
    .use(ElementPlus, { locale: zhCn })
    .use(router)
    .use(VMdEditor)
    .use(VMdPreview)
    .use(VueEasyLightbox)
    .mount('#app');