<template>
    <MySpace class="sidebar"></MySpace>
    <div style="display: flex; justify-content: center; align-items: center;">
        <div class="photo">
            <div class="card"
                style="display: flex; justify-content: center; align-items: center; font-family: 阿里妈妈刀隶体 Regular;">
                <div class="row" style="display: flex; justify-content: center;font-size: 30px;">
                    头像修改
                </div>
                <div class="card-body">
                    <img :src="photo" alt="头像" style="width: 40vh; height: 40vh;" />
                    <hr>
                </div>
                <div class="caption" style="text-align: center;">
                    <el-button plain @click="changePhotoOpenView(true)">更改头像</el-button>
                </div>
            </div>
        </div>
    </div>
    <el-dialog v-model="changePhotoVisible" title="更改头像" width="35%" center align-center
        style="font-family: 阿里妈妈刀隶体 Regular;font-size: 30px;">
        <el-input v-model="photo" placeholder="请输入图片URL地址" />
        <img-upload v-model="url"></img-upload>
        <el-upload class="img-upload" ref="upload" action=" https://www.scqblog.com/api/covers" :on-preview="handlePreview"
            :on-remove="handleRemove" :before-remove="beforeRemove" :on-success="handleSuccess" multiple :limit="1"
            :on-exceed="handleExceed" :file-list="fileList" style="display: flex; justify-content: center;">
            <el-button type="primary" style="margin-top: 10px;">
                Upload<el-icon class="el-icon--right">
                    <Upload />
                </el-icon>
            </el-button>
        </el-upload>
        <div class="el-upload__tip" style="display: flex;
    justify-content: center;
    align-items: center;">只能上传jpg/png文件，且不超过500kb;或者直接填写URL</div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="changePhotoOpenView(false)">取消</el-button>
                <el-button type="primary" @click="updatePhoto">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import MySpace from '../../../components/MySpace.vue'
import { useStore } from 'vuex';
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import $ from 'jquery';
import { Upload } from '@element-plus/icons-vue'
export default {
    components: {
        MySpace, Upload
    },
    setup() {
        const store = useStore()

        let photo = ref('')
        let old_photo = ref('')
        let changePhotoVisible = ref(false)
        const getinfo = () => {
            photo.value = store.state.user.photo;
            old_photo.value = store.state.user.photo;
        }

        getinfo();
        const profileUpdate = reactive({
            error_message: '',
        })
        const updatePhoto = () => {
            profileUpdate.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/user/account/update/photo/",
                type: "post",
                data: {
                    photo: photo.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profileUpdate.error_message = resp.error_message;
                        store.dispatch("getinfo", {
                            success() {
                                getinfo();
                                changePhotoOpenView(false);
                            },
                            error() {
                            }
                        })
                        update_success();
                    } else {
                        profileUpdate.error_message = resp.error_message;
                        update_error();
                    }
                },
                error() {
                }
            })
        }
        const removePhoto = () => {
            profileUpdate.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/user/photo/remove/",
                type: "post",
                data: {
                    old_photo: old_photo.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success() {
                },
                error() {
                }
            })
        }
        const changePhotoOpenView = (keyword) => {
            changePhotoVisible.value = keyword
        }
        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
            removePhoto();
        }
        const update_error = () => {
            ElMessage.error(profileUpdate.error_message)
        }

        const fileList = ref([])
        const url = ref('')

        const handleRemove = () => {
        }

        const handlePreview = () => {
        }

        const handleExceed = (files, fileList) => {
            ElMessage.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
        }

        const beforeRemove = (file) => {
            url.value = "";
            return ElMessageBox.confirm(`确定移除 ${file.name}？`)
        }

        const handleSuccess = (response) => {
            url.value = response;// 触发自定义事件，并传递 response 数据
            photo.value = response;
            ElMessage.warning('上传成功');

        }
        return {
            store,
            photo,
            profileUpdate,
            updatePhoto, update_success, update_error, changePhotoOpenView,
            changePhotoVisible, fileList, url, handleRemove, handlePreview,
            handleExceed, beforeRemove, handleSuccess, old_photo, removePhoto,
        }
    }
}
</script>
<style scoped>
.card {
    width: 60vh;
    height: 60vh;
}
</style>