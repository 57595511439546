<template>
    <div class="container" style="font-family: 阿里妈妈刀隶体 Regular;">
        <div class="row justify-content-center">
            <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2">
                            <img :src="$store.state.user.photo" alt="头像" style="width: 8vh; border-radius: 20%">
                        </div>
                        <div class="col-10">
                            <div class="add-news-text-field" @click="addDrawer_up">
                                分享快乐事！
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-infinite-scroll="load">
            <div v-for="(post, i) in smallposts" :key="i">
                <div class="row justify-content-center">
                    <div class="card" style="margin-top: 2.5vh; width: 80vh; height: 22vh;">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-2">
                                    <img :src="post.userPhoto" alt="楼主头像" @click="jumpUser(post.userNickname)"
                                        style="width: 8vh;height: 8vh; border-radius: 20% ;cursor: pointer;">
                                </div>
                                <div class="col-7">
                                    <span class="post-usernickname" style="font-family: 阿里妈妈刀隶体 Regular;
                                    font-size: 20px;cursor: pointer;" @click="jumpUser(post.userNickname)">{{
                                        post.userNickname }}</span>
                                    <br>
                                    <span class="post-time" style="font-family: 阿里妈妈刀隶体 Regular;">{{ post.modifyTime
                                    }}</span>
                                </div>
                                <div class="col-2">
                                </div>
                                <div class="col-1">
                                    <div v-if="($store.state.user.root === '1')">
                                        <el-dropdown>
                                            <el-icon>
                                                <More />
                                            </el-icon>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="confirmedDelete(post.id, post.userId)">删除</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="post-title" style="display: flex;justify-content: center;
                                align-items: center; cursor: pointer;" @click="jumpPost(post.id)">
                                <h5 style="font-weight: 600;font-size: 25px;">{{ post.title }}</h5>
                            </div>
                            <hr style="margin: 5px 0px 0px 0px;">
                            <div class="row like">
                                <div class="col-6" style="text-align: center;">
                                    <div>
                                        <div>
                                            <img src="../../assets/images/赞.png" alt=""
                                                style="width: 3vh; height: 3vh; cursor: pointer;"
                                                @click="add_post_like(post.id)">
                                            <span style="color: #95a5a6; padding-left:1vh ;">{{ post.likeNum }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6" style="text-align: center;">
                                    <div>
                                        <div>
                                            <img src="../../assets/images/评论1.png" alt=""
                                                style="width: 3vh; height: 3vh;cursor: pointer;" @click="jumpPost(post.id)">
                                            <span style="color: #95a5a6;padding-left:1vh ;">{{ post.commentNum
                                            }}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-drawer v-model="addDrawer" title="留言了" direction="btt" :before-close="addHandleClose"
        style="width: 140vh; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);font-family: 阿里妈妈刀隶体 Regular; font-size: 60px;"
        size="100%">
        <template #title>
            <div class="title_center">留言了</div>
        </template>
        <div class="content_center">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-input v-model="input_title" maxlength="20" show-word-limit placeholder="给你的留言起个标题吧~"
                    style="width: 30vh; text-align: center;" />
            </div>
        </div>
        <div class="content_center" style="padding: 5vh;">
            <v-md-editor v-model="textarea_content" height="1000px" :include-level="[1, 2, 3, 4, 5]"
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji| tip| todo-list"
                right-toolbar="preview toc sync-scroll fullscreen" :disabled-menus="[]" @upload-image="handleUploadImage"
                accept="image/*"></v-md-editor>
        </div>
        <div class="content_center" style="display: flex; justify-content: center; align-items: center;">
            <el-button type="primary" @click="addPost">发布</el-button>
        </div>
    </el-drawer>
    <br>
</template>

<script>
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import $ from 'jquery';
import { useStore } from 'vuex';
import { More } from '@element-plus/icons';

export default {
    components: {
        More,

    },
    setup() {
        const addDrawer = ref(false)   // 发帖抽屉  
        let input_title = ref('')       // 帖子起标题
        let textarea_content = ref('')  // 帖子写正文
        let bigposts = ref([])      // 从后端返回来的全部帖子数据
        let smallposts = ref([])    // 显示到页面的小数组
        let postsize = ref(1)       // 用来计算小数组的倍率

        const store = useStore()
        const profile = reactive({
            error_message: '',
        })

        let goods = ref('')
        let status = ref('')

        // 获取全部帖子信息
        const getAllPosts = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/post/get/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    bigposts.value = resp;
                    smallposts.value = resp.slice(0, 10);    //选出前5条
                }
            })
        }

        getAllPosts();

        // 滚动触底加载新条目，每次5条(想做判断语句的，但是没时间优化了)
        const load = () => {
            setTimeout(() => {
                smallposts.value = bigposts.value.slice(0, 10 * postsize.value);
                postsize.value++
            }, 100)
        }

        // 打开发帖编辑窗口
        const addDrawer_up = () => {
            addDrawer.value = true;
        }

        // 关闭发帖窗口前弹出询问
        const addHandleClose = () => {
            ElMessageBox.confirm('没保存的内容会丢失哦，退出账号重新登录没发布的内容也会丢失哦，确定退出编辑吗？')
                .then(() => {
                    addDrawer.value = false;
                })
                .catch(() => {
                })
        }

        // 发布帖子
        const addPost = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/post/add/",
                type: "post",
                data: {
                    title: input_title.value,
                    content: textarea_content.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 发布成功提示
        const add_success = () => {
            ElMessage({
                message: '发布成功~',
                type: 'success',
            })
            addDrawer.value = false;
            getAllPosts();
            input_title.value = "";
            textarea_content.value = "";
        }



        // 删除前弹出询问
        const confirmedDelete = (post_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deletePost(post_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除帖子
        const deletePost = (post_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/post/delete/",
                type: "post",
                data: {
                    post_id: post_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 删除成败提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            getAllPosts();
        }

        // 操作失败提示
        const error = () => {
            ElMessage.error(profile.error_message)
        }

        // 打开原文页面
        const jumpPost = (id) => {
            window.open(`/interaction/post/?id=${id}`, '_blank');
            localStorage.setItem('previousRoute', `/interaction/post/?id=${id}`);

        }

        const jumpUser = (username) => {
            window.open(`/InformationView/?username=${username}`, '_blank');
            localStorage.setItem('previousRoute', `/InformationView/?username=${username}`);
        }

        //点赞逻辑

        const add_post_like = (id) => {
            status.value = id;
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/add/good/status/",
                type: "post",
                data: {
                    post_id: status.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success() {
                    add_post();
                }
            })
        }
        const add_post = () => {
            goods.value = 1;
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/add/good/",
                type: "post",
                data: {
                    post_id: status.value,
                    good: goods.value,
                    user_id: store.state.user.id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        if (goods.value == 1) { good_success(); }
                        else {
                            goods_success();
                        }
                    } else {
                        profile.error_message = resp.error_message;
                        good_error();
                    }
                }
            })
        }
        const good_success = () => {
            ElMessage({
                message: '~成功~',
                type: 'success',
            })
            getAllPosts();
        }
        const goods_success = () => {
            ElMessage({
                message: '撤回成功~',
                type: 'success',
            })
            getAllPosts();
        }

        // 操作失败提示
        const good_error = () => {
            ElMessage.error(profile.error_message)
        }

        /**
         * 实现本地图片上传
         */
        const handleUploadImage = (event, insertImage, files) => {
            for (let i in files) {
                const formData = new FormData();
                formData.append('file', files[i]);
                console.log(files[i])
                $.ajax({
                    url: ` https://www.scqblog.com/api/article/`,
                    type: 'POST',
                    data: formData,
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    processData: false,
                    contentType: false,
                    success: response => {
                        insertImage({
                            url: response,
                            desc: files[i].name,
                        });
                    },
                    error: (xhr, status, error) => {
                        console.log('请求失败了', error);
                    },
                });
            }
        };

        return {
            addDrawer,
            input_title,
            textarea_content,
            bigposts,
            smallposts,
            postsize,
            store,
            profile,
            getAllPosts,
            load,
            addDrawer_up,
            addHandleClose,
            addPost,
            add_success,
            confirmedDelete,
            deletePost,
            delete_success,
            error,
            jumpPost, goods, add_post_like, good_success, good_error, status
            , goods_success, jumpUser, handleUploadImage


        }
    }
}
</script>

<style scoped>
@font-face {
    font-family: 阿里妈妈刀隶体 Regular;
    src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.title_center {
    text-align: center;
    font-family: 阿里妈妈刀隶体 Regular;
    font-size: 60px;
}

.add-news-text-field {
    background-color: #F0F2F5;
    cursor: pointer;
    height: 45px;
    border-radius: 50px;
    padding: 10px 10px 10px 15px;
    font-size: 20px;
    color: #656768;
}

.post-usernickname {
    font-size: 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #333333;
}

.post-time {
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #808080;
}

.like {
    margin-top: 8px;
}

@media screen and (max-width: 500px) {
    .col-10 {
        padding: 0px;
    }

    .add-news-text-field {
        background-color: #F0F2F5;
        cursor: pointer;
        height: 45px;
        width: 200px;
        border-radius: 50px;
        margin: 0px 20px;
        padding: 10px 10px 10px 15px;
        font-size: 20px;
        color: #656768;
    }

    .col-7 {
        margin: 0px 20px;
    }
}
</style>
