export default {
    state: {
        id: "",
        user_id: "",
        username: "",
        author: "",
        user_photo: "",
        time: "",
        status: "",
    },
    getters: {
    },
    mutations: {
        // 清除缓存
        clean_attentionadd() {
            localStorage.removeItem("id");
            localStorage.removeItem("user_id");
            localStorage.removeItem("username");
            localStorage.removeItem("author");
            localStorage.removeItem("user_photo");
            localStorage.removeItem("time");
            localStorage.removeItem("status");
        },

        // 同步更新state书本信息
        updateAttention(state, post) {
            state.id = post.id;
            state.user_id = post.user_id;
            state.username = post.username;
            state.author = post.author;
            state.user_photo = post.user_photo;
            state.time = post.time;
            state.status = post.status;
        },
    },
    actions: {
    },
    modules: {
    }
}