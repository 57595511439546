const GAME_OBJECTS = [];//存储游戏对象

export class GameObject {
    constructor() {
        GAME_OBJECTS.push(this);//将每一个GameObject存下来
        this.timedelta = 0;//当前这一帧与上一帧的时间间隔
        this.has_called_start = false;//表示当前对象是否执行过start()函数
    }

    start() {// 初始执行一次

    }

    update() { // 每一帧执行一次（除了第一帧之外）

    }

    on_destory() {//删除之前的操作

    }

    destory() {// 删除当前对象
        this.destory();

        for (let i in GAME_OBJECTS) {
            const obj = GAME_OBJECTS[i];
            if (obj == this) {
                GAME_OBJECTS.splice(i);
                break;
            }
        }
    }
}
let last_timestamp;//上一帧执行的时刻
const step = timestamp => {//timestamp表示当前函数执行的时刻是什么时刻
    for (let obj of GAME_OBJECTS) {
        if (!obj.has_called_start) {
            obj.has_called_start = true;
            obj.start();
        } else {
            obj.timedelta = timestamp - last_timestamp;//时间的更新
            obj.update();
        }
    }

    last_timestamp = timestamp;
    requestAnimationFrame(step);
}

requestAnimationFrame(step);//启动游戏循环