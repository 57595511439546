import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import HomeView from '../views/HomeView.vue'
import UserAccountLoginView from '@/views/user/account/UserAccountLoginView'
import UserAccountRegisterView from '@/views/user/account/UserAccountRegisterView'
import BlogView from '@/views/navbar/BlogView'
import PostView from '@/views/navbar/PostView'
import NProgress from 'nprogress';
import PostInsertView from '@/views/navbar/PostInsertView'

import ShowInfo from '@/views/user/myspace/ShowInfo'
import CountControl from '@/views/user/myspace/CountControl'
import InfoEditor from '@/views/user/myspace/InfoEditor'
import ImageUp from '@/views/user/myspace/ImageUp'
import MyMessage from '@/views/user/myspace/artical/MyMessage'
import MyArticle from '@/views/user/myspace/artical/MyArticle'
import AttentionArticle from '@/views/user/myspace/artical/AttentionArticle'
import MyAttentionAuthor from '@/views/user/myspace/artical/MyAttentionAuthor'
import CollectionAns from '@/views/user/myspace/collection/CollectionAns'
import CollectionArticle from '@/views/user/myspace/collection/CollectionArticle'
import BlogArticle from '@/views/navbar/BlogArticle'

import ImS from '@/views/ImS'


import GameView from '@/views/game/GameView'

import MdTest from '@/views/utils/md/MdTest'
import InformationView from '@/views/navbar/InformationView'
import BugView from '@/views/user/myspace/BugView'

import StudyHome from '@/views/study/StudyHome'

import WuHome from '@/views/game/五子棋/WuHome'
import GoHome from '@/views/game/围棋/GoHome'
import 'nprogress/nprogress.css';
NProgress.configure({
  showSpinner: false, // 加载小图标是否显示
  ease: 'ease', // 通过 ease(一个 CSS 中的 easing 值) 调整动画设置
  speed: 500, // 速度 speed （毫秒ms）
  minimum: 0.1, // 通过 minimum 来修改最小百分比
  trickle: false, // 关闭进度条步进，设置 trickle 为 false
  trickleRate: 0.02, // 调整 trickleRate (每次步进增长多少) 
  trickleSpeed: 800, // 步进间隔，单位毫秒ms
})

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/home/",
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/go/',
    name: 'go_home',
    component: GoHome,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/wuziqi/',
    name: 'wu_home',
    component: WuHome,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/home/',
    name: 'home_index',
    component: HomeView,
    meta: {
      requestAuth: false,
    }
  },
  {
    path: '/study/home/',
    name: 'study_home',
    component: StudyHome,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/BugView/',
    name: 'bug_view',
    component: BugView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/InformationView/',
    name: 'information_view',
    component: InformationView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/MdTest/',
    name: 'MdTest',
    component: MdTest,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/interaction/post/',
    name: 'interaction_post',
    component: PostInsertView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/game/view/',
    name: 'game_view',
    component: GameView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/ImS/',
    name: 'ImS',
    component: ImS,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/blog/article/',
    name: 'blog_article',
    component: BlogArticle,
    meta: {
      requestAuth: true,
      // title: '文章区'
    }
  },
  {
    path: '/showinfo/',
    name: 'showinfo',
    component: ShowInfo,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/infoeditor/',
    name: 'infoeditor',
    component: InfoEditor,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/countcontrol/',
    name: 'countcontrol',
    component: CountControl,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/imageUp/',
    name: 'imageUp',
    component: ImageUp,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/MyMessage/',
    name: 'MyMessage',
    component: MyMessage,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/MyArticle/',
    name: 'MyArticle',
    component: MyArticle,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/AttentionArticle/',
    name: 'AttentionArticle',
    component: AttentionArticle,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/MyAttentionAuthor/',
    name: 'my_attention_author',
    component: MyAttentionAuthor,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/collectionAns/',
    name: 'collectionAns',
    component: CollectionAns,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/collectionArticle/',
    name: 'collectionArticle',
    component: CollectionArticle,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: '/BlogView/',
    name: 'blog_view',
    component: BlogView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: '/PostView/',
    name: 'post_view',
    component: PostView,
    meta: {
      requestAuth: true,
    }
  },
  {
    path: "/user/account/login/",
    name: "user_account_login",
    component: UserAccountLoginView,
    meta: {
      requestAuth: false,
    },
  },
  {
    path: "/user/account/register/",
    name: "user_account_register",
    component: UserAccountRegisterView,
    meta: {
      requestAuth: false,
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requestAuth && !store.state.user.is_login) {
    NProgress.start();
    next({ name: "user_account_login" });
  } else {
    NProgress.start();
    next();
  }
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
  // document.title = to.meta.title  //为每个页面添加名字
})
export default router
