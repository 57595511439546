export default {
    state: {
        username: "",
        photo: "",
        introduction: "",
        nickname: "",
        stateDrawer: false,
    },
    getters: {
    },
    mutations: {
        updateFriend(state, friend) {
            state.username = friend.username;
            state.photo = friend.photo;
            state.introduction = friend.introduction;
            state.nickname = friend.nickname;
        },
        updateState(state, friend) {
            state.stateDrawer = friend.stateDrawer;
        }
    },
    actions: {
    },
    modules: {
    }
}
