<template>
    <MySpace class="sidebar"></MySpace>
    <div class="container" style="font-family: 阿里妈妈刀隶体 Regular; font-size: 30px;">
        <div class="row justify-content-center">
            <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                <div class="card-body">
                    <div class="row" style="display: flex; justify-content: center;">
                        我的说说
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="padding-top: 2.5vh;">
        <div v-for="(post, i) in tableData" :key="i" shadow="hover">
            <div class="row justify-content-center">
                <div class="card" style="margin-top: 2.5vh; width: 80vh; height: 20vh;cursor: pointer;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-2">
                                <img :src="$store.state.user.photo" alt="楼主头像" class="photo">
                            </div>
                            <div class="col-7">
                                <span class="post-usernickname">{{ post.userNickname }}</span>
                                <br>
                                <span class="post-time">{{ post.createTime }}</span>
                            </div>
                            <div class="col-2">
                                <div v-if="(post.userId != $store.state.user.id)">
                                    <el-button type="primary" round>关注</el-button>
                                </div>
                            </div>
                            <div class="col-1">
                                <div>
                                    <el-dropdown>
                                        <el-icon>
                                            <More />
                                        </el-icon>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item
                                                    @click="updateDrawer_up(post.id, post.title, post.content)">修改</el-dropdown-item>
                                                <el-dropdown-item
                                                    @click="confirmedDelete(post.id, post.userId)">删除</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="post-title" style="display: flex;justify-content: center;
                                align-items: center;" @click="jumpPost(post.id)">
                            <h5 style="font-weight: 600;">{{ post.title }}</h5>
                        </div>
                        <hr style="margin: 0px 0px 0.6vh 0px;">
                        <div class="row">
                            <div class="col-6" style="text-align: center;">
                                <div>
                                    <img src="../../../../assets/images/点赞.png" alt="" style="width: 4vh; height: 4vh;">
                                    <span style="color: #95a5a6; padding-left:1vh ;">{{ post.likeNum }}</span>
                                </div>
                            </div>
                            <div class="col-6" style="text-align: center;padding-top: 0.5vh;">
                                <div>
                                    <img src="../../../../assets/images/评论.png" alt="" style="width: 3vh; height: 3vh;">
                                    <span style="color: #95a5a6; padding-left:1vh ;">{{ post.commentNum
                                    }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="page"
            style="position: fixed; bottom: 3vh; left: 50%; transform: translateX(-50%); background-color: white; padding: 10px;">
            <el-pagination v-model:current-page="currentPage" :page-size="3" :small="small" background
                layout="total, prev, pager, next" :total="total" @current-change="changePostpage" />
        </div>
    </div>
    <el-drawer v-model="updateDrawer" title="修改一下" direction="btt" :before-close="updateHandleClose"
        style="width: 140vh; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);font-family: 阿里妈妈刀隶体 Regular; font-size: 60px;"
        size="100%">
        <template #title>
            <div class="title_center">修改一下</div>
        </template>
        <div class="content_center">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-input v-model="input_title_update" maxlength="20" show-word-limit placeholder="给你的留言起个标题吧~"
                    style="width: 30vh; text-align: center;" />
            </div>
        </div>
        <div class="content_center" style="padding: 5vh;">
            <v-md-editor v-model="textarea_content_update" height="1000px" :include-level="[1, 2, 3, 4, 5]"
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji| tip| todo-list"
                right-toolbar="preview toc sync-scroll fullscreen" :disabled-menus="[]" @upload-image="handleUploadImage"
                accept="image/*"></v-md-editor>
        </div>
        <div class="content_center" style="display: flex; justify-content: center; align-items: center;">
            <el-button type="primary" @click="updatePost">发布</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { ref, reactive } from 'vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import MySpace from '../../../../components/MySpace.vue'
import router from '@/router';
import { More } from '@element-plus/icons';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
    components: {
        MySpace,
        More,

    },

    setup() {
        let tableData = ref([])    // 显示到页面的小数组
        let total = ref()
        let homes = ref([])
        let currentPage = ref(1)
        let select = ref('post')
        let post_id_update = ref('')          // 要修改的帖子的id
        let input_title_update = ref('')    // 帖子改标题
        let textarea_content_update = ref('')   // 帖子改正文
        const updateDrawer = ref(false)
        const profile = reactive({
            error_message: '',
        })
        const store = useStore()
        const jumpPost = (id) => {
            router.push({
                name: "interaction_post",
                path: "interaction/post",
                query: {
                    id: id
                }
            })
        }

        // 获取动态total
        const getPostTotal = () => {
            select.value = 'home';
            $.ajax({
                url: " https://www.scqblog.com/api/user/myspace/getTotal/post/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    total.value = resp;
                }
            })
        }

        getPostTotal();
        const getPostPageOne = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/message/",
                type: "get",
                data: {
                    page: 1
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableData.value = resp;
                    currentPage.value = 1;
                }
            })
        }

        getPostPageOne();
        const changePostpage = (number) => {
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/message/",
                type: "get",
                data: {
                    page: number
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableData.value = resp;
                }
            })
        }
        // 修改帖子
        const updatePost = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/post/rewrite/",
                type: "post",
                data: {
                    post_id: post_id_update.value,
                    title: input_title_update.value,
                    content: textarea_content_update.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        update_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }
        // 打开改帖编辑窗口
        const updateDrawer_up = (post_id, title, content) => {
            updateDrawer.value = true;
            post_id_update.value = post_id;
            input_title_update.value = title;
            textarea_content_update.value = content;
        }

        // 关闭改帖窗口前弹出询问
        const updateHandleClose = () => {
            ElMessageBox.confirm('确定不更改了吗？')
                .then(() => {
                    updateDrawer.value = false;
                    post_id_update.value = '';
                    input_title_update.value = '';
                    textarea_content_update.value = '';
                })
                .catch(() => {
                })
        }


        // 修改成功提示
        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
            updateDrawer.value = false;
            getPostPageOne();
            getPostTotal();
            post_id_update = '';
            input_title_update.value = "";
            textarea_content_update.value = "";
        }

        // 删除前弹出询问
        const confirmedDelete = (post_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deletePost(post_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除帖子
        const deletePost = (post_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/interaction/post/delete/",
                type: "post",
                data: {
                    post_id: post_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 删除成败提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            getPostPageOne();
            getPostTotal();
        }

        // 操作失败提示
        const error = () => {
            ElMessage.error(profile.error_message)
        }
        /**
         * 实现本地图片上传
         */
        const handleUploadImage = (event, insertImage, files) => {
            for (let i in files) {
                const formData = new FormData();
                formData.append('file', files[i]);
                console.log(files[i])
                $.ajax({
                    url: ` https://www.scqblog.com/api/article/`,
                    type: 'POST',
                    data: formData,
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    processData: false,
                    contentType: false,
                    success: response => {
                        insertImage({
                            url: response,
                            desc: files[i].name,
                        });
                    },
                    error: (xhr, status, error) => {
                        console.log('请求失败了', error);
                    },
                });
            }
        };
        return {
            tableData, jumpPost,
            total, homes, getPostTotal, getPostPageOne, changePostpage,
            currentPage, updateDrawer_up, error, delete_success, deletePost,
            profile, confirmedDelete, post_id_update, input_title_update,
            textarea_content_update, updatePost, updateDrawer,
            updateHandleClose, handleUploadImage
        }
    }
}
</script>

<style scoped>
.photo {
    width: 8vh;
    height: 8vh;
    border-radius: 20%;
}

.title_center {
    text-align: center;
    font-family: 阿里妈妈刀隶体 Regular;
    font-size: 60px;
}

.add-news-text-field {
    background-color: #F0F2F5;
    cursor: pointer;
    height: 45px;
    border-radius: 50px;
    padding: 10px 10px 10px 15px;
    font-size: 20px;
    color: #656768;
}

.file-content-field {
    max-height: 150px;
    overflow: hidden;
}

.post-usernickname {
    font-size: 16px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #333333;
}

.post-time {
    font-size: 14px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #808080;
}

.icon {
    font-size: 22px;
    color: #b3b3b3;
}

.myiconfont {
    font-family: "iconfont" !important;
    font-size: 22px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #b3b3b3;
}

.myiconfont-like {
    font-family: "iconfont" !important;
    font-size: 22px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #73bffa;
}
</style>
