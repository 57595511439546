<template>
    <MySpace class="sidebar"></MySpace>
    <div style="display: flex;justify-content: center;align-items: center;">
        <div class="card" style="font-family: 阿里妈妈刀隶体 Regular; width: 100vh;height: 75vh;margin-top: 10vh;">
            <div class="card-body">
                <div class="row" style="margin-left: 20px; margin-right: 20px;">
                    <h3 style="margin-top: 20px; margin-bottom: 20px;display: flex;justify-content: center;
    align-items: center;font-size: 40px;">信息修改</h3>
                    <hr>
                    <div class="img" style="display: flex;justify-content: center;align-items: center;">
                        <img :src="photo" alt="" style="width: 150px;height: 150px;padding-bottom: 2vh;">
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <span style="font-weight: 600;font-size: 25px;">昵称</span>
                        </div>
                        <div class="col-8" style="font-size: 20px;">
                            <input class="inputs  no-border" type="text" v-model="nickname">
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <span style="font-weight: 600;font-size: 25px;">邮箱</span>
                        </div>
                        <div class="col-8" style="font-size: 20px;">
                            <input class="inputs  no-border" type="text" v-model="email">
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <span style="font-weight: 600;font-size: 25px;">电话</span>
                        </div>
                        <div class="col-8" style="font-size: 20px;">
                            <input class="inputs  no-border" type="text" v-model="phone">
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-2">
                            <span style="font-weight: 600;font-size: 25px;">座右铭</span>
                        </div>
                        <div class="col-8" style="font-size: 20px;">
                            <input class="inputs  no-border" type="text" v-model="introduction">
                        </div>
                    </div>
                    <hr>
                    <div class="sss" style="display: flex;justify-content: center;align-items: center;">
                        <el-button type="success" style="width: 100px;" @click="updateMajor" round>修改信息</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import MySpace from '../../../components/MySpace.vue'
import { useStore } from 'vuex';
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import $ from 'jquery';
import router from '@/router';
export default {
    components: {
        MySpace
    },
    setup() {
        const store = useStore()

        let username = ref('')
        let nickname = ref('')
        let email = ref('')
        let introduction = ref('')
        let photo = ref('')
        let phone = ref('')

        const getinfo = () => {
            username.value = store.state.user.username;
            nickname.value = store.state.user.nickname;
            email.value = store.state.user.email;
            introduction.value = store.state.user.introduction;
            photo.value = store.state.user.photo;
            phone.value = store.state.user.phone;
        }

        getinfo();

        const profileUpdate = reactive({
            error_message: '',
        })

        const updateMajor = () => {
            profileUpdate.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/user/account/update/major/",
                type: "post",
                data: {
                    nickname: nickname.value,
                    email: email.value,
                    introduction: introduction.value,
                    phone: phone.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profileUpdate.error_message = resp.error_message;
                        store.dispatch("getinfo", {
                            success() {
                                getinfo();
                            },
                            error() {
                            }
                        })
                        update_success();
                    } else {
                        profileUpdate.error_message = resp.error_message;
                        update_error();
                    }
                },
                error() {
                }
            })
        }
        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
        }
        const update_error = () => {
            ElMessage.error(profileUpdate.error_message)
        }

        // 确认框
        const open = () => {
            ElMessageBox.confirm(
                '您需要重新登录以进行正常体验',
                '修改成功!',
                {
                    confirmButtonText: '确认',
                    type: 'info',
                }
            )
                .then(() => {
                    store.dispatch("logout");
                    router.push({ name: "user_account_login" });
                })
                .catch(() => {
                    store.dispatch("logout");
                    router.push({ name: "user_account_login" });
                })
        }
        return {
            store,
            username,
            nickname,
            email,
            introduction,
            photo,
            phone,
            profileUpdate,
            updateMajor, update_success, update_error, open
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* 可根据需要调整容器高度 */
    /* 其他样式设置 */
}

.no-border {
    border: none;
    width: 400px;
}
</style>