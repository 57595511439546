import { GameObject } from "./GameObject";

export class ChessGo extends GameObject {
    constructor(gamemap) {
        super();
        this.gamemap = gamemap;

        this.allChess = [];
        this.checkGo = [];

        this.ID = 1;
    }
    /**
     * 初始化地图
     */

    initGo() {
        for (let i = 0; i < 20; i++) {
            this.checkGo[i] = [];
            for (let j = 0; j < 20; j++) {
                this.checkGo[i][j] = 0;
            }
        }
    }

    /**
     * 添加棋子
     */
    addChess(x, y) {
        if (this.checkGo[x][y] == 0 && x > 0 && y > 0) {
            this.allChess.push({ id: this.ID, x: x, y: y });
            console.log(this.allChess);
            this.checkGo[x][y] = this.ID;

            this.checkAll();  // 检查棋盘上所有的棋子
            if (this.ID == 1) {
                this.ID = 2;
            } else if (this.ID == 2) {
                this.ID = 1;
            }

        }
    }

    checkElimination(x, y) {
        const opponentId = 3 - this.checkGo[x][y]; // 对手的id，假设只有1和2两个玩家

        // 检查四个方向的棋子
        const directions = [[-1, 0], [1, 0], [0, -1], [0, 1]];
        for (const direction of directions) {
            const dx = direction[0];
            const dy = direction[1];

            const i = x + dx;
            const j = y + dy;

            // 如果对手的棋子被完全包围，就消除
            if (i >= 0 && i < 20 && j >= 0 && j < 20 && this.checkGo[i][j] === opponentId && this.isSurrounded(i, j)) {
                this.eliminateChess(opponentId, i, j);
                return true;
            }
        }
        return false;
    }

    isSurrounded(x, y) {
        const id = this.checkGo[x][y];
        const visited = Array(20).fill(0).map(() => Array(20).fill(false)); // 记录已经检查过的位置

        const directions = [[-1, 0], [1, 0], [0, -1], [0, 1]];

        const dfs = (i, j) => {
            if (i < 0 || i >= 20 || j < 0 || j >= 20 || visited[i][j]) {
                return true;
            }

            visited[i][j] = true;

            if (this.checkGo[i][j] === 0) { // 如果有空位，说明没有被包围
                return false;
            } else if (this.checkGo[i][j] === id) { // 如果是同色棋子，继续检查
                for (const direction of directions) {
                    const dx = direction[0];
                    const dy = direction[1];
                    if (!dfs(i + dx, j + dy)) {
                        return false;
                    }
                }
            }

            return true;
        }

        return dfs(x, y);
    }

    eliminateChess(id, x, y) {
        const directions = [[-1, 0], [1, 0], [0, -1], [0, 1]];

        const dfs = (i, j) => {
            if (i < 0 || i >= 20 || j < 0 || j >= 20 || this.checkGo[i][j] !== id) {
                return;
            }
            this.checkGo[i][j] = 0; // 移除棋子

            for (const direction of directions) {
                const dx = direction[0];
                const dy = direction[1];
                // 从 allChess 数组中移除被消除的棋子
                for (let k = 0; k < this.allChess.length; k++) {
                    if (this.allChess[k].x === i && this.allChess[k].y === j) {
                        this.allChess.splice(k, 1);
                        console.log(this.allChess)
                        break;
                    }
                }
                dfs(i + dx, j + dy);
            }
        }

        dfs(x, y);
    }
    checkAll() {
        for (let i = 0; i < 20; i++) {
            for (let j = 0; j < 20; j++) {
                if (this.checkGo[i][j] !== 0 && this.isSurrounded(i, j)) {
                    this.eliminateChess(this.checkGo[i][j], i, j);
                }
            }
        }
    }

    start() {
        this.initGo();
    }

    update() {
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        for (const cell of this.allChess) {
            if (cell.id == 1) {
                ctx.fillStyle = "black";
            } else if (cell.id == 2) {
                ctx.fillStyle = "white";
            }

            ctx.beginPath();
            ctx.arc((cell.y + 1) * L, (cell.x + 1) * L, L / 4, 0, Math.PI * 2);
            ctx.fill();

        }
    }
}