<template>
    <body>
        <div class="from">
            <div class="submit">
                <span class="form_title">个人信息提交</span>
                <div class="form_input">
                    <span>昵称：</span>
                    <input class="inputs" type="text" v-model="nickname">
                </div>
                <div class="form_input">
                    <span>邮箱:</span>
                    <input class="inputs" type="text" v-model="email">
                </div>
                <div class="form_input">
                    <span>电话：</span>
                    <input class="inputs" type="text" v-model="phone">
                </div>
                <div class="form_input">
                    <span>座右铭：</span>
                    <input class="inputs" type="text" v-model="introduction">
                </div>
                <div class="form_input">
                    <span>课程名称：</span>
                    <input class="inputs" type="text" value="网页设计与制作">
                </div>
                <div class="btn_submit">
                    <button class="btn" @click="updateMajor">提交</button>
                </div>
            </div>
        </div>
    </body>
</template>
<script></script>
<style></style>