<template>
    <MySpace class="sidebar"></MySpace>
    <div style="display: flex;justify-content: center;align-items: center;">
        <div class="card" style="font-family: 阿里妈妈刀隶体 Regular; width: 100vh;height: 75vh;margin-top: 10vh;">
            <div class="card-body">
                <div class="row" style="margin-left: 20px; margin-right: 20px;">
                    <h3 style="margin-top: 10px; margin-bottom: 10px;display: flex;justify-content: center;
    align-items: center;font-size: 40px;">个人信息</h3>
                    <hr>
                    <div class="img" style="display: flex;justify-content: center;align-items: center;">
                        <img :src="photo" alt="" style="width: 150px;height: 150px;padding-bottom: 2vh;">
                    </div>
                    <hr>
                    <div class="row account">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row">
                                <div class="col-4">
                                    账号
                                </div>
                                <div class="col-8">
                                    {{ username }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row">
                                <div class="col-4">
                                    昵称
                                </div>
                                <div class="col-8">
                                    {{ nickname }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row">
                                <div class="col-4">
                                    邮箱
                                </div>
                                <div class="col-8">
                                    {{ email }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row">
                                <div class="col-4">
                                    电话
                                </div>
                                <div class="col-8">
                                    {{ phone }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-1"></div>
                        <div class="col-11">
                            <div class="row">
                                <div class="col-4">
                                    座右铭
                                </div>
                                <div class="col-8">
                                    {{ introduction }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import MySpace from '../../../components/MySpace.vue'
import { useStore } from 'vuex';
import { ref } from 'vue';

export default {
    components: {
        MySpace
    },

    setup() {
        const store = useStore()

        let username = ref('')
        let nickname = ref('')
        let email = ref('')
        let introduction = ref('')
        let photo = ref('')
        let phone = ref('')

        const getinfo = () => {
            username.value = store.state.user.username;
            nickname.value = store.state.user.nickname;
            email.value = store.state.user.email;
            introduction.value = store.state.user.introduction;
            photo.value = store.state.user.photo;
            phone.value = store.state.user.phone;
        }

        getinfo();
        return {
            store,
            username,
            nickname,
            email,
            introduction,
            photo,
            phone
        }
    }
}
</script>
    
<style scoped>
@media screen and (max-width: 768px) {
    .sidebar {
        display: none;
    }

    .account {
        margin: 0px;
        padding: 0px;
    }

}
</style>
    