<template>
    <div class="bjimg"></div>
    <div class="nav_study" style="font-family:阿里妈妈数黑体 Bold;">
        <div class="study" style="float: left;">
            <el-menu class="menu_study" :collapse="isCollapse" style="background-color: rgba(128, 128, 128, 0.1);border-radius: 10px;
                border-right: none !important;">
                <el-menu-item index="1" @click="getIndexOne()">
                    <el-icon>
                        <House />
                    </el-icon>
                    <template #title>主页</template>
                </el-menu-item>
                <el-menu-item index="2" @click="getIndexTow()">
                    <el-icon>
                        <Memo />
                    </el-icon>
                    <template #title>排行榜</template>
                </el-menu-item>
                <el-menu-item index="3" @click="getIndexThree()">
                    <el-icon>
                        <document />
                    </el-icon>
                    <template #title>帮助文档</template>
                </el-menu-item>
                <el-menu-item index="4">
                    <el-icon>
                        <setting />
                    </el-icon>
                    <template #title>Navigator Four</template>
                </el-menu-item>
            </el-menu>
        </div>
        <div v-if="activeIndex === 1">
            <el-row class="cool-row">
                <el-col :span="24" class="cool-col">
                    <div class="clock">
                        <div style="text-align: center;font-size: 50px;height: 60px;">{{ nowTimeref }}
                        </div>
                        <div style="text-align: center;font-size: 20px;margin: 0px 0;">{{ nowDateref }} &nbsp; {{
                            nowWeekref }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row style="margin-top: 5px;">
                <el-col :span="6" :offset="1">
                    <el-card class="box-card" @click="check()">
                        <template #header>
                            <div class="card-header" style="display: flex;
                            align-items: center;justify-content: center;">
                                <span>学习记录</span>
                            </div>
                        </template>
                        <div class="img">
                            <img src="../../assets/study/study_3.png" alt="" style="max-width: 100%; max-height: 100%;">
                        </div>
                    </el-card>
                    <el-col :span="16" class="memorandum_el" style="margin-top: 5vh;">
                        <div class="memorandum">
                            <div class="header">备忘录</div>
                            <div class="body">
                                <el-input v-model="input" placeholder="不要忘记事情哦！" class="input_text" type="textarea" />
                            </div>
                            <div class="bottom" style="text-align: center;">
                                <el-button type="info" round @click="addMemo()">保存</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-col>
                <el-col :span="10" style="background-color: rgb(175, 192, 169);height: 50vh;">
                    <el-card>
                        <div class="plan_header" style="height: 5vh;">
                            每日规划
                        </div>
                        <hr style="margin-top: 5px;margin-bottom: 0;">
                        <div class="content" style="height: 40vh;overflow: auto;">
                            <div class="text">
                                <v-md-preview :text="text"></v-md-preview>
                            </div>
                        </div>
                        <hr style="margin-top: 5px;margin-bottom: 0;">
                        <div class="button" style="margin-top: 5px;text-align: center;">
                            <el-button type="primary" round @click="MakePlane()">制定</el-button>
                            <el-button type="success" round @click="MakePlane()">修改</el-button>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="4" class="memorandum_el" :offset="2">
                    <el-popover placement="top-start" title="小提示" :width="200" trigger="hover" content="当您离开页面在返回后,计时器会清0,但学习时长仍在计算,点击停止后,
                        可再次点击战斗开始学习,计时器会重新计时。">
                        <template #reference>

                            <div class="memorandum">
                                <div class="header" style="font-size: 35px;">本次学习时间</div>
                                <div class="bodys" style="font-size: 40px;margin-top: 3vh;">
                                    <span id="clock"
                                        style="display: inline-block; width: 300px;padding-left: 5vh;padding-right: 5vh;">{{
                                            formattedTime }}</span>
                                </div>
                                <div class="bottom" style="text-align: center;margin-top: 3vh;">
                                    <el-button type="warning" round @click="addStudy()">战斗</el-button>
                                    <el-button type="danger" round @click="endStudy()">停止</el-button>
                                </div>
                            </div>
                        </template>
                    </el-popover>
                </el-col>
            </el-row>
        </div>
        <el-drawer v-model="studyRecordDrawer" title="学习记录" direction="btt"
            style="width: 50vh; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);" size="80%"
            :show-close="false">
            <template #title>
                <div class="title_center">学习记录</div>
            </template>
            <div class="content_center" style=" max-height: 70vh;">
                <div v-for="item in studyList" :key="item" class="card-item">
                    <el-card class="hover-pointer" style="opacity: 0.8;">
                        <div class="card-header">
                            <div style="text-align: center;font-size: 12px;">
                                开始时间：{{ item.startTime }} &nbsp;时长：{{ item.totalTime }}
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-drawer>
        <el-drawer v-model="makePlane" title="每日规划" direction="btt" style="width: 120vh; position: fixed;top: 50%; left: 50%; transform: translate(-50%, -50%);
         font-size: 60px;" size="80%">
            <template #title>
                <div class="title_center">每日规划</div>
            </template>
            <div class="content_center">
                <v-md-editor v-model="text" height="1000px" @save="save()" :include-level="[1, 2, 3, 4, 5]"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji | tip| todo-list"
                    right-toolbar="preview toc sync-scroll fullscreen" :disabled-menus="[]"
                    @upload-image="handleUploadImage" accept="image/*">
                </v-md-editor>
            </div>
            <div class="content_center" style="display: flex;justify-content: center;align-items: center;">
                <el-button type="primary" @click="addPlane()">确定</el-button>
            </div>
        </el-drawer>
        <div v-if="activeIndex === 2">
            <el-row class="rank-row">
                <el-col :span="24" class="rank-col" style="color: #fff;">
                    <div class="rank_list">
                        <div style="text-align: center;font-size: 50px;height: 60px;">排行榜
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row class="hero-row">
                <el-col class="hero-col" :span="6" :offset="3">
                    <div class="hero-title" style="font-size: 30px; text-align: center;">
                        个人榜
                    </div>
                    <el-card class="hero-pointer" style="opacity: 0.8;">
                        <hr>
                        <div class="rank_lone" v-for="table in tableRankLone" :key="table">
                            <div class="total-time" style="float: left;width: 25vh;">
                                {{ table.years }} 年 {{ table.months }} 月
                                {{ table.days }} 日 &nbsp;
                            </div>
                            <div class="total-times" style="float: left;width: 10vh;">
                                {{ (table.dayTime / (60)).toFixed(2) }}
                            </div>
                            <div class="total-mintues">
                                分
                            </div>
                            <hr>
                        </div>
                    </el-card>
                </el-col>
                <el-col class="hero-col" :span="8" :offset="4">
                    <div class="hero-title" style="font-size: 30px; text-align: center;">
                        英雄榜
                    </div>
                    <el-card class="hero-pointer" style="opacity: 0.8;height: 45vh;">
                        <hr>
                        <div class="rank_lone" v-for="table in tableRankTotal" :key="table">
                            <div class="total-name" style="float: left;width: 20vh;">
                                用户：{{ table.username }} &nbsp;
                            </div>
                            <div class="total-time" style="float: left;width: 20vh;">
                                {{ table.years }} 年 {{ table.months }} 月
                                {{ table.days }} 日 &nbsp;
                            </div>
                            <div class="total-times" style="float: left;width: 10vh;">
                                {{ (table.dayTime / (60)).toFixed(2) }}
                            </div>
                            <div class="total-mintues">
                                分
                            </div>
                            <hr>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </div>
        <div v-if="activeIndex === 3">
            <el-row>
                <el-col :span="8" class="help_text" :offset="8" style="margin-top: 10vh;">
                    <v-md-preview :text="help_text"></v-md-preview>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import { ref, reactive, onMounted } from 'vue'
import {
    Document,
    Setting,
    House,
    Memo,
} from '@element-plus/icons-vue'
import { useStore } from 'vuex';
import $ from 'jquery';
import { ElMessage } from 'element-plus';
export default {
    components: {
        Document,
        Setting,
        House, Memo
    },
    setup() {
        const isCollapse = ref(true)
        const store = useStore();
        const studyRecordDrawer = ref(false)
        let activeIndex = ref('');
        const studyList = ref([]);
        const input = ref('');
        const help_text = ref('');
        const nowDateref = ref("")
        const nowTimeref = ref("")
        const nowWeekref = ref("")
        const tableData = ref([])
        const tableRankLone = ref([])
        const tableRankTotal = ref([])
        const makePlane = ref(false)
        let check_start = ref('');
        const text = ref('')
        let timer = null;
        const formattedTime = ref('00:00:00');
        let startTimes = null; // 固定的开始时间
        const profile = reactive({
            error_message: '',
        })

        const getInfo = () => {
            check_start.value = store.state.user.check_start;
        }
        getInfo();
        //钟表时间获取
        const getDate1 = () => {
            const mm = new Date().getMonth() + 1;
            const dd = new Date().getDate();
            const week = new Date().getDay();
            const hh = new Date().getHours();
            const ms = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            if (week === 1) {
                nowWeekref.value = '星期一';
            } else if (week === 2) {
                nowWeekref.value = '星期二';
            } else if (week === 3) {
                nowWeekref.value = '星期三';
            } else if (week === 4) {
                nowWeekref.value = '星期四';
            } else if (week === 5) {
                nowWeekref.value = '星期五';
            } else if (week === 6) {
                nowWeekref.value = '星期六';
            } else {
                nowWeekref.value = '星期天';
            }
            if (hh <= 9) {
                nowTimeref.value = '0' + hh + ':' + ms;
            } else {
                nowTimeref.value = hh + ':' + ms;
            }
            nowDateref.value = mm + '月' + dd + '日';
        };
        onMounted(() => {
            setInterval(getDate1, 500);
        })

        //根据侧边导航栏进行不同页面展示 
        const getIndexOne = () => {
            activeIndex.value = 1;
        }
        getIndexOne();
        const getIndexTow = () => {
            activeIndex.value = 2;
            getStudyListLone();
            getStudyListTotal();
        }
        const getIndexThree = () => {
            activeIndex.value = 3;
            help_text.value = `
### 帮助文档

本功能旨在帮助更多使用电脑学习的人进行时间管理，本模块的功能不多，需要注意的一点是，当关闭界面时，如果还在学习的情况下，一定要先点击停止按钮，否则的话学习的时间会一直在服务器中累加的。

> 网站的创作者是一个新手，所以网站很多时候并不能适配很多屏幕，会造成视觉混乱，请大家见谅，关于适配的话，后期会一个一个页面进行改进，敬请期待。
  `;
        }
        //根据侧边导航栏进行不同页面展示 

        //弹出学习记录界面
        const check = () => {
            studyRecordDrawer.value = true;
            getStudyList();
        }
        const getStudyList = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/get/study/list/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    studyList.value = resp;
                }
            })
        }

        //每日规划
        const MakePlane = () => {
            makePlane.value = true;
        }

        //计划的制订
        const addPlane = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/add/plane/",
                type: "post",
                data: {
                    context: text.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_error();
                    }
                }
            })
        }

        const add_success = () => {
            ElMessage({
                message: '计划制定成功~',
                type: 'success',
            });
            makePlane.value = false;
        }

        const add_error = () => {
            ElMessage.error(profile.error_message)
        }

        //计划的显示
        const getPlane = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/get/plane/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    text.value = resp.content;
                    textContent();
                }
            })
        }
        const textContent = () => {
            if (text.value == null)
                text.value = "### 还未制定计划"
        }
        getPlane();

        //计划的制订
        const addMemo = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/add/memo/",
                type: "post",
                data: {
                    context: input.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        Memo_success();
                    } else {
                        profile.error_message = resp.error_message;
                        Memo_error();
                    }
                }
            })
        }

        const Memo_success = () => {
            ElMessage({
                message: '备忘成功~',
                type: 'success',
            });
        }

        const Memo_error = () => {
            ElMessage.error(profile.error_message)
        }

        //计划的显示
        const getMemo = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/get/memo/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    input.value = resp.content;
                }
            })
        }
        getMemo();

        const addStudy = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/study/track/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        startTimes = new Date();
                        timer = setInterval(updateClock, 1000);
                        profile.error_message = resp.error_message;
                        add_study_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_study_error();
                    }
                }
            })
        }
        const add_study_error = () => {
            ElMessage.error(profile.error_message)
        }

        const add_study_success = () => {
            ElMessage({
                message: '学习开始了，加油吧少年~',
                type: 'success',
            })
            store.commit('updateStudy', "true");
            check_start.value = "true";
        }
        //结束学习
        const endStudy = () => {
            profile.error_message = "";
            if (check_start.value === "false") {
                end_error();
            } else {
                $.ajax({
                    url: " https://www.scqblog.com/api/end/study/track/",
                    type: "post",
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    success(resp) {
                        if (resp.error_message === "success") {
                            profile.error_message = resp.error_message;
                            end_success();
                        }
                    }
                })
            }
        }
        const end_error = () => {
            ElMessage({
                message: '还未开始学习~',
                type: 'error',
            })
        }
        const end_success = () => {
            ElMessage({
                message: '学习之路暂时停下，但人生之路还未到尽头~',
                type: 'success',
            })
            store.commit('updateStudy', "false");
            check_start.value = "flase";
            clearInterval(timer);
            getStudyRank();
            getStudyListLone();
            getStudyListTotal();
        }

        //更新学习时间
        const getStudyRank = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/ranklist/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success() {
                }
            })
        }

        //实时更新学习时间
        const updateClock = () => {
            const currentTime = new Date();
            const timeDiff = currentTime.getTime() - startTimes.getTime(); // 计算时间差
            const hours = Math.floor(timeDiff / (1000 * 60 * 60)).toString().padStart(2, '0');
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000).toString().padStart(2, '0');
            formattedTime.value = `${hours}:${minutes}:${seconds}`;
        }

        //获得个人排行榜(日榜)
        const getStudyListLone = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/ranklist/lone/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableRankLone.value = resp;
                }
            })
        }
        //获得英雄排行榜(日榜)
        const getStudyListTotal = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/study/ranklist/total/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableRankTotal.value = resp;
                }
            })
        }
        return {
            isCollapse, store,
            activeIndex, check, profile,
            getIndexTow, studyRecordDrawer, getIndexThree,
            getIndexOne, studyList, getStudyList,
            input, nowDateref, nowTimeref, nowWeekref,
            getDate1, tableData, MakePlane, makePlane,
            text, addPlane, add_success, add_error, getPlane,
            textContent, getMemo, Memo_error, Memo_success,
            addMemo, addStudy, end_error, end_success, add_study_error,
            add_study_success, endStudy, getStudyRank, check_start,
            formattedTime, updateClock, timer, startTimes, help_text, getInfo,
            tableRankLone, getStudyListLone, tableRankTotal, getStudyListTotal
        }
    }
}
</script>
  
<style>
@font-face {
    font-family: "阿里妈妈数黑体 Bold";
    src: url('../../assets/fonts/AlimamaShuHeiTi-Bold.ttf');
}

.lone {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bodys {
    background-color: rgba(233, 236, 236, 0.202);
    display: flex;
    align-items: center;
    justify-content: center;
}

.plan_header {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cool-col {
    color: #fff;
}

.memorandum_el {
    border-radius: 5%;
}

.input_text {
    .el-textarea__inner {
        resize: none;
        height: 20vh;
    }
}

.header {
    padding: 3px 0px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom {
    padding-top: 1vh;
}

.memorandum_el {
    height: 30vh;
    background-color: rgba(15, 192, 192, 0.202);
}


header.el-drawer__header {
    padding: 10px 0px 0px 0px;
    margin-bottom: 0px;
}

::-webkit-scrollbar {
    display: none;
}

.title_center {
    text-align: center;
    font-size: 30px;
}

.el-card {
    cursor: pointer;
}

.box-card {
    width: 30vh;
}

.box-card .el-card__header {
    padding: 5px;
    opacity: 0.8;
}

.box-card .el-card__body {
    padding: 0;
}

.el-menu-item:hover {
    background-color: transparent !important;
}

.el-menu-item:hover .el-icon {
    transform: scale(1.2);
}

.nav_study {
    margin-top: 5vh;
}

.menu_study {
    height: 80vh;
}

.bjimg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    zoom: 1;
    background-color: #fff;
    background-image: url('../../assets/study/study_4.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
</style>
  