<template>
    <el-row class="total">
        <div class="centered-text">
            <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
                <el-menu-item index="1" class="big">
                    <router-link :class="route_name == 'showinfo' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'showinfo' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 25px; margin-bottom: 10px;">
                                <svg t="1689584035289" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="2593" width="50px" height="50px">
                                    <path
                                        d="M896 256v512H128V256h768zM213.333333 341.333333v341.333334h597.333334V341.333333H213.333333z m554.666667 85.333334v85.333333h-256v-85.333333h256z m-85.333333 128v85.333333h-170.666667v-85.333333h170.666667z m-213.333334 64H256C256 558.933333 302.933333 512 362.666667 512s106.666667 46.933333 106.666666 106.666667z m-213.333333 0h213.333333v21.333333H256v-21.333333zM362.666667 512c-34.133333 0-64-29.866667-64-64S328.533333 384 362.666667 384s64 29.866667 64 64S396.8 512 362.666667 512z"
                                        fill="#444444" p-id="2594"></path>
                                </svg>
                            </i>信息简介
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="2" class="big">
                    <router-link :class="route_name == 'infoeditor' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'infoeditor' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 20px; margin-bottom: 20px;">
                                <svg t="1689584346166" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="5386" width="40" height="40">
                                    <path
                                        d="M275.6 782c-8.5 0-16.6-3.4-22.6-9.4l-138-138c-12.5-12.5-12.5-32.8 0-45.3l398.6-398.6c8.9-8.9 33.5-29.5 71.2-29.5 26.9 0 52.2 10.5 71.2 29.5l40.9 40.9c39.2 39.2 39.2 103.1 0 142.4L654 417C538.2 532.7 418.5 652.3 298.2 772.6c-6 6-14.1 9.4-22.6 9.4z m-92.7-170l92.7 92.7c112.7-112.7 224.7-224.6 333.2-333l42.9-42.9c14.3-14.3 14.3-37.7 0-51.9L610.8 236c-6.9-6.9-16.1-10.7-26-10.7-14.5 0-24.2 8.9-26 10.7L182.9 612z"
                                        p-id="5387"></path>
                                    <path
                                        d="M96.7 822.9c-8.4 0-16.6-3.3-22.6-9.4-8.1-8.1-11.2-19.9-8.3-30.9l47.6-177.5c3-11 11.6-19.7 22.6-22.6 11-3 22.8 0.2 30.9 8.3l130 129.9c8.1 8.1 11.2 19.9 8.3 30.9-3 11-11.6 19.7-22.6 22.6L105 821.8c-2.8 0.7-5.5 1.1-8.3 1.1z m64.2-147.7L142 745.6l70.5-18.9-51.6-51.5zM607.2 419.2c-8.3 0-16.6-3.2-22.8-9.6L463.1 286.1c-12.4-12.6-12.2-32.9 0.4-45.3 12.6-12.4 32.9-12.2 45.3 0.4L630 364.8c12.4 12.6 12.2 32.9-0.4 45.3-6.2 6-14.3 9.1-22.4 9.1zM922.9 813.3H402.1c-17.7 0-32-14.3-32-32s14.3-32 32-32h520.8c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                                        p-id="5388"></path>
                                    <path
                                        d="M922.9 677.3H599.6c-17.7 0-32-14.3-32-32s14.3-32 32-32h323.3c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                                        p-id="5389"></path>
                                    <path
                                        d="M921.9 543.8H725.6c-17.7 0-32-14.3-32-32s14.3-32 32-32h196.3c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                                        p-id="5390"></path>
                                </svg>
                            </i>信息修改
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="3" class="big">
                    <router-link :class="route_name == 'imageUp' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'imageUp' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 30px;">
                                <svg t="1689584990179" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="5545" width="30px" height="30px">
                                    <path
                                        d="M773.184 192.768l-6.208-21.888-9.536-34.24c-7.68-45.76-37.248-76.416-68.48-76.416h-353.92c-31.232 0-60.8 30.656-69.184 79.68l-14.976 52.864H126.464c-36.096 0-66.24 32.128-66.24 72.64v625.664c0 40.576 30.08 72.704 66.24 72.704h771.072c36.096 0 66.24-32.128 66.24-72.704V265.408c0-40.512-30.08-72.64-66.24-72.64H773.12z m45.504-60.224h78.848c70.272 0 126.464 59.904 126.464 132.864v625.664c0 72.96-56.192 132.928-126.464 132.928H126.464C56.192 1024 0 964.096 0 891.072V265.408c0-72.96 56.192-132.864 126.464-132.864H205.44l1.728-5.824C219.328 54.016 271.552 0 334.976 0h354.048c63.424 0 115.648 54.016 127.104 123.456l2.56 9.088z"
                                        fill="#333333" p-id="5546"></path>
                                    <path
                                        d="M512 825.6a224 224 0 1 1 0-448 224 224 0 0 1 0 448z m0-64a160 160 0 1 0 0-320 160 160 0 0 0 0 320zM800 343.488a32 32 0 1 1 0-64h64a32 32 0 1 1 0 64h-64z"
                                        fill="#333333" p-id="5547"></path>
                                </svg>
                            </i>头像修改
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="4" class="big">
                    <router-link :class="route_name == 'countcontrol' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'countcontrol' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 25px;">
                                <svg t="1689585160549" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="6552" width="35px" height="35px">
                                    <path
                                        d="M780.281 960.897h-45.595c-10.634 0-19.286-8.651-19.286-19.286v-28.01a151.879 151.879 0 0 1-33.217-13.812l-19.843 19.843c-7.519 7.52-19.755 7.52-27.274 0l-32.241-32.241c-7.52-7.52-7.52-19.755 0-27.275L622.84 840.1a151.974 151.974 0 0 1-13.557-33.044h-28.439c-10.634 0-19.286-8.651-19.286-19.286v-45.595c0-10.634 8.651-19.286 19.286-19.286h28.817a152.03 152.03 0 0 1 13.66-32.563l-20.498-20.498c-7.52-7.52-7.52-19.755 0-27.274l32.241-32.241c7.519-7.519 19.755-7.519 27.274 0l20.715 20.715a152.026 152.026 0 0 1 32.346-13.332v-29.361c0-10.634 8.651-19.286 19.286-19.286h45.595c10.634 0 19.286 8.651 19.286 19.286v29.405a151.998 151.998 0 0 1 32.291 13.344l20.77-20.77c7.52-7.519 19.755-7.519 27.275 0l32.24 32.241c7.52 7.52 7.52 19.755 0 27.274l-20.606 20.606a151.945 151.945 0 0 1 13.598 32.455h28.986c10.634 0 19.286 8.651 19.286 19.286v45.595c0 10.634-8.651 19.286-19.286 19.286h-28.605a151.965 151.965 0 0 1-13.497 32.938l20.123 20.122c7.52 7.52 7.52 19.755 0 27.275l-32.24 32.24c-7.52 7.52-19.755 7.52-27.275 0l-19.906-19.906a151.929 151.929 0 0 1-33.154 13.826v28.059c0 10.634-8.651 19.286-19.285 19.286z m-36.83-28.052h28.063v-41.746l11.215-2.295c15.877-3.248 30.785-9.468 44.309-18.485l9.568-6.38 29.659 29.659 19.843-19.843-29.75-29.75 6.254-9.541c8.856-13.508 14.939-28.369 18.08-44.168l2.245-11.291h42.418v-28.063h-42.577l-2.331-11.157c-3.267-15.632-9.43-30.33-18.317-43.685l-6.365-9.564 30.344-30.344-19.843-19.843-30.412 30.412-9.544-6.269c-13.334-8.758-27.995-14.815-43.579-18.002l-11.216-2.294v-43.093h-28.063v43.075l-11.231 2.284c-15.599 3.172-30.28 9.22-43.637 17.976l-9.541 6.254-30.342-30.342-19.843 19.843 30.251 30.251-6.38 9.568c-8.917 13.375-15.102 28.103-18.379 43.777l-2.333 11.155h-42.406v28.063h42.253l2.244 11.293c3.145 15.826 9.248 30.716 18.14 44.257l6.267 9.544-29.657 29.657 19.843 19.843 29.583-29.583 9.565 6.367c13.54 9.013 28.468 15.221 44.37 18.453l11.233 2.283-0.001 41.724z"
                                        fill="" p-id="6553"></path>
                                    <path
                                        d="M757.482 825.982c-33.641 0-61.009-27.368-61.009-61.009s27.368-61.01 61.009-61.01 61.01 27.369 61.01 61.01-27.369 61.009-61.01 61.009z m0-90.018c-15.995 0-29.009 13.014-29.009 29.01 0 15.995 13.014 29.009 29.009 29.009 15.996 0 29.01-13.014 29.01-29.009 0-15.996-13.013-29.01-29.01-29.01zM435.127 523.994c-57.223 0-111.021-22.284-151.484-62.747-40.463-40.463-62.747-94.261-62.747-151.484s22.284-111.021 62.747-151.484c40.463-40.463 94.261-62.747 151.484-62.747 57.223 0 111.021 22.284 151.484 62.747 40.463 40.463 62.747 94.261 62.747 151.484s-22.284 111.021-62.747 151.484c-40.464 40.463-94.262 62.747-151.484 62.747z m0-364.858c-83.057 0-150.628 67.571-150.628 150.628s67.571 150.628 150.628 150.628 150.628-67.571 150.628-150.628-67.572-150.628-150.628-150.628z"
                                        fill="" p-id="6554"></path>
                                    <path
                                        d="M100.395 911.908c-15.513 0-29.093-11.365-31.422-27.169-2.628-17.836-4.259-36.085-4.259-54.241 0-49.825 10.063-98.175 29.323-143.71 18.596-43.965 45.21-83.443 79.104-117.337 33.894-33.895 73.372-60.509 117.337-79.105 45.535-19.26 93.885-29.025 143.71-29.025 95.713 0 127.822-7.947 196.878 58.286a372.024 372.024 0 0 1 21.643 22.585c11.607 13.181 10.331 33.276-2.85 44.883-13.18 11.609-33.277 10.331-44.883-2.85a309.038 309.038 0 0 0-17.936-18.714c-57.155-54.819-73.628-40.586-152.852-40.586-168.494 0-305.573 137.08-305.573 305.573 0 15.064 1.104 30.193 3.281 44.969 2.56 17.376-9.45 33.538-26.826 36.098a32.26 32.26 0 0 1-4.675 0.343z"
                                        fill="" p-id="6555"></path>
                                    <path
                                        d="M537.67 912.57H102.603c-19.009 0-34.418-14.238-34.418-31.801s15.409-31.801 34.418-31.801H537.67c19.008 0 34.418 14.238 34.418 31.801s-15.41 31.801-34.418 31.801z"
                                        fill="" p-id="6556"></path>
                                </svg>
                            </i>
                            账号管理
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="5" class="big">
                    <router-link :class="route_name == 'MyArticle' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'MyArticle' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 20px;">
                                <svg t="1689585328866" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="6893" width="35px" height="35px">
                                    <path
                                        d="M815.8 870h-530c-69.6 0-126.2-56.6-126.2-126.2v-530c0-69.6 56.6-126.2 126.2-126.2h530c69.6 0 126.2 56.6 126.2 126.2v530c0 69.6-56.6 126.2-126.2 126.2z m-530-731.9c-41.7 0-75.7 34-75.7 75.7v530c0 41.7 34 75.7 75.7 75.7h530c41.7 0 75.7-34 75.7-75.7v-530c0-41.7-34-75.7-75.7-75.7h-530z"
                                        fill="#828282" p-id="6894"></path>
                                    <path
                                        d="M756.7 360.8H347.1c-13.9 0-25.2-11.4-25.2-25.2 0-13.9 11.4-25.2 25.2-25.2h409.6c13.9 0 25.2 11.4 25.2 25.2 0.1 13.8-11.3 25.2-25.2 25.2zM569.1 504.1h-228c-10.6 0-19.2-8.6-19.2-19.2v-12.1c0-10.6 8.6-19.2 19.2-19.2h228c10.6 0 19.2 8.6 19.2 19.2v12.1c0 10.5-8.6 19.2-19.2 19.2zM569.1 647.3h-228c-10.6 0-19.2-8.6-19.2-19.2V616c0-10.6 8.6-19.2 19.2-19.2h228c10.6 0 19.2 8.6 19.2 19.2v12.1c0 10.6-8.6 19.2-19.2 19.2z"
                                        fill="#828282" p-id="6895"></path>
                                </svg>
                            </i>
                            我的文章
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="6" class="big">
                    <router-link :class="route_name == 'MyMessage' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'MyMessage' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 20px;">
                                <svg t="1692524796514" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="4711" width="35" height="35">
                                    <path
                                        d="M644.608 436.6336 743.424 436.6336c11.6736 0 21.0944-9.3184 21.0944-20.7872 0-11.4688-9.4208-20.7872-21.0944-20.7872l-41.2672 0 40.1408-30.3104c5.5296-10.1376 1.7408-22.7328-8.4992-28.16-10.24-5.4272-23.04-1.7408-28.5696 8.3968l-72.0896 51.712c-2.7648 1.1264-5.12 2.6624-7.168 4.7104-6.0416 5.8368-8.192 14.848-4.7104 22.9376C625.152 433.4592 635.0848 438.4768 644.608 436.6336L644.608 436.6336zM644.608 436.6336"
                                        fill="#272636" p-id="4712"></path>
                                    <path
                                        d="M661.8112 126.2592 362.1888 126.2592C190.6688 126.2592 51.2 262.0416 51.2 429.056l0 61.3376c0 174.08 145.8176 319.8976 314.368 319.8976l293.2736 0c31.232 0 91.136 110.8992 127.0784 83.0464 35.9424-27.8528 10.1376-114.8928 21.8112-121.1392C905.216 718.9504 974.2336 615.0144 972.8 490.3936L972.8 429.056C972.8 262.0416 833.3312 126.2592 661.8112 126.2592L661.8112 126.2592zM933.1712 512.1024c0 86.6304-61.3376 173.2608-137.728 220.4672-44.3392 21.504-30.5152 60.8256-30.5152 98.6112 0 37.888-55.9104-62.3616-84.992-62.3616l-73.216 0L346.3168 768.8192c-119.808 0-252.928-138.9568-252.928-256.7168l0-98.0992c0-104.3456 115.0976-246.1696 249.5488-246.1696l339.8656 0c121.344 0 250.2656 121.2416 250.2656 246.1696L933.0688 512.1024 933.1712 512.1024zM933.1712 512.1024"
                                        fill="#272636" p-id="4713"></path>
                                    <path
                                        d="M262.3488 415.8464c0 11.4688 9.4208 20.7872 21.0944 20.7872l83.3536 0c11.6736 0 21.0944-9.3184 21.0944-20.7872 0-11.264-9.0112-20.3776-20.3776-20.6848 11.3664-0.4096 20.3776-9.4208 20.3776-20.7872 0-11.4688-9.4208-20.7872-21.0944-20.7872l-83.3536 0c-11.6736 0-21.0944 9.3184-21.0944 20.7872 0 11.264 9.1136 20.3776 20.3776 20.7872C271.36 395.4688 262.3488 404.5824 262.3488 415.8464L262.3488 415.8464zM262.3488 415.8464"
                                        fill="#272636" p-id="4714"></path>
                                    <path
                                        d="M748.544 511.7952c0 0-109.568 70.2464-238.7968 70.2464-123.4944 0-223.3344-20.5824-223.3344-20.5824-11.8784-1.8432-23.04 6.2464-24.9856 18.1248-1.9456 11.9808 6.144 23.1424 18.0224 25.088 0 0 90.3168 18.7392 230.2976 18.7392 169.2672 0 257.1264-74.4448 257.1264-74.3424 10.4448-5.0176 14.7456-17.408 9.728-27.648C771.4816 511.1808 758.8864 506.88 748.544 511.7952L748.544 511.7952zM748.544 511.7952"
                                        fill="#272636" p-id="4715"></path>
                                </svg>
                            </i>
                            我的说说
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="7" class="big"><router-link
                        :class="route_name == 'my_attention_author' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'my_attention_author' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 25px;">
                                <svg t="1689586340605" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="10272" width="30" height="30">
                                    <path
                                        d="M768 213.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-256 0q-42.666667 0-42.666666-42.666667l0 0q0-42.666667 42.666666-42.666667l256 0q42.666667 0 42.666667 42.666667Z"
                                        fill="#516280" p-id="10273"></path>
                                    <path
                                        d="M1024 384m0 42.666667l0 0q0 42.666667-42.666667 42.666666l-512 0q-42.666667 0-42.666666-42.666666l0 0q0-42.666667 42.666666-42.666667l512 0q42.666667 0 42.666667 42.666667Z"
                                        fill="#007BFC" p-id="10274"></path>
                                    <path
                                        d="M85.333333 256l0 170.666667 170.666667 0 0-170.666667-170.666667 0m0-85.333333l170.666667 0a85.333333 85.333333 0 0 1 85.333333 85.333333l0 170.666667a85.333333 85.333333 0 0 1-85.333333 85.333333l-170.666667 0a85.333333 85.333333 0 0 1-85.333333-85.333333l0-170.666667a85.333333 85.333333 0 0 1 85.333333-85.333333z"
                                        fill="#516280" p-id="10275"></path>
                                    <path
                                        d="M768 213.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-256 0q-42.666667 0-42.666666-42.666667l0 0q0-42.666667 42.666666-42.666667l256 0q42.666667 0 42.666667 42.666667Z"
                                        fill="#516280" p-id="10276"></path>
                                    <path
                                        d="M1024 597.333333m0 42.666667l0 0q0 42.666667-42.666667 42.666667l-938.666666 0q-42.666667 0-42.666667-42.666667l0 0q0-42.666667 42.666667-42.666667l938.666666 0q42.666667 0 42.666667 42.666667Z"
                                        fill="#516280" p-id="10277"></path>
                                    <path
                                        d="M1024 768m0 42.666667l0 0q0 42.666667-42.666667 42.666666l-938.666666 0q-42.666667 0-42.666667-42.666666l0 0q0-42.666667 42.666667-42.666667l938.666666 0q42.666667 0 42.666667 42.666667Z"
                                        fill="#516280" p-id="10278"></path>
                                </svg>
                            </i>
                            我的关注
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="8" class="big"><router-link
                        :class="route_name == 'AttentionArticle' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'AttentionArticle' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 20px;">
                                <svg t="1689585725747" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="8255" width="35px" height="35px">
                                    <path
                                        d="M512 131.2l-345.6 188.8 345.6 169.6 345.6-169.6-345.6-188.8z m16-64l416 224c22.4 12.8 22.4 44.8 0 57.6l-416 208c-9.6 3.2-19.2 3.2-28.8 0l-416-208c-22.4-12.8-22.4-44.8 0-57.6l416-224c6.4-3.2 19.2-3.2 28.8 0z m-419.2 416l403.2 198.4 403.2-198.4c16-6.4 35.2 0 41.6 16 6.4 16 0 35.2-16 41.6l-428.8 214.4-428.8-214.4c-16-6.4-22.4-25.6-16-41.6 9.6-16 28.8-22.4 41.6-16z m0 192l403.2 198.4 403.2-198.4c16-6.4 35.2 0 41.6 16 6.4 16 0 35.2-16 41.6l-428.8 214.4-428.8-214.4c-16-6.4-22.4-25.6-16-41.6 9.6-16 28.8-22.4 41.6-16z"
                                        fill="#374E6B" p-id="8256"></path>
                                </svg>
                            </i>
                            关注文章
                        </div>
                    </router-link>
                </el-menu-item>

                <el-menu-item index="9" class="big"><router-link
                        :class="route_name == 'collectionArticle' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'collectionArticle' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 15px; margin-bottom: 15px;">
                                <svg t="1689586737382" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="12433" width="40" height="40">
                                    <path
                                        d="M875.157333 521.386667l74.709334-85.461334c26.090667-29.824 13.866667-67.52-24.768-76.309333L681.728 304.213333l-127.786667-214.677333c-20.288-34.069333-59.946667-34.090667-80.213333 0l-127.786667 214.677333-243.392 55.381334c-38.442667 8.746667-50.837333 46.506667-24.768 76.309333l164.394667 188.053333-22.613333 248.917334c-3.605333 39.466667 28.437333 62.805333 64.874666 47.146666l229.376-98.432-16.810666-39.210666-229.397334 98.453333c-6.698667 2.88-6.229333 3.221333-5.546666-4.096l24.256-267.093333-176.426667-201.813334c-4.757333-5.461333-4.885333-5.034667 2.133333-6.634666l261.205334-59.434667 137.173333-230.4c3.733333-6.293333 3.114667-6.293333 6.869333 0l137.152 230.4 261.205334 59.434667c7.125333 1.621333 6.954667 1.088 2.133333 6.613333l-74.709333 85.482667 32.106666 28.074666z m0 0l74.709334-85.461334c26.090667-29.824 13.866667-67.52-24.768-76.309333L681.728 304.213333l-127.786667-214.677333c-20.288-34.069333-59.946667-34.090667-80.213333 0l-127.786667 214.677333-243.392 55.381334c-38.442667 8.746667-50.837333 46.506667-24.768 76.309333l164.394667 188.053333-22.613333 248.917334c-3.605333 39.466667 28.437333 62.805333 64.874666 47.146666l229.376-98.432-16.810666-39.210666-229.397334 98.453333c-6.698667 2.88-6.229333 3.221333-5.546666-4.096l24.256-267.093333-176.426667-201.813334c-4.757333-5.461333-4.885333-5.034667 2.133333-6.634666l261.205334-59.434667 137.173333-230.4c3.733333-6.293333 3.114667-6.293333 6.869333 0l137.152 230.4 261.205334 59.434667c7.125333 1.621333 6.954667 1.088 2.133333 6.613333l-74.709333 85.482667 32.106666 28.074666z"
                                        fill="#3D3D3D" p-id="12434"></path>
                                    <path
                                        d="M682.666667 917.333333a192 192 0 1 0 0-384 192 192 0 0 0 0 384z m0 42.666667c-129.6 0-234.666667-105.066667-234.666667-234.666667s105.066667-234.666667 234.666667-234.666666 234.666667 105.066667 234.666666 234.666666-105.066667 234.666667-234.666666 234.666667z"
                                        fill="#3D3D3D" p-id="12435"></path>
                                    <path
                                        d="M769.429333 646.122667a21.333333 21.333333 0 0 1 29.930667 30.421333l-143.978667 141.653333a21.333333 21.333333 0 0 1-30.037333-0.106666l-64.426667-64.426667a21.333333 21.333333 0 0 1 30.165334-30.186667l49.472 49.472 128.853333-126.826666z"
                                        fill="#3D3D3D" p-id="12436"></path>
                                </svg>
                            </i>
                            我的收藏
                        </div>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="10" class="big"><router-link
                        :class="route_name == 'ImS' ? 'nav-link active' : 'nav-link'" :to="{ name: 'ImS' }">
                        <div style="display: flex; align-items: center;">
                            <i class="el-icon-user" style="margin-right: 12px; margin-bottom: 12px;">
                                <svg t="1692524689039" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="3615" width="40" height="40">
                                    <path
                                        d="M308 412v268c0 36.779 9.678 71.962 27.805 102.907a205.39 205.39 0 0 0 73.288 73.288C440.038 874.322 475.22 884 512 884c36.779 0 71.962-9.678 102.907-27.805a205.39 205.39 0 0 0 73.288-73.288C706.322 751.962 716 716.78 716 680V412H308z m484 172v96c0 6.503-0.222 12.954-0.658 19.346C859.931 728.636 908 796.705 908 876a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8c0-44.24-23.94-82.888-59.57-103.697a278.635 278.635 0 0 1-22.658 49.018 281.39 281.39 0 0 1-100.45 100.45C611.84 946.072 563.546 960 512 960s-99.84-13.929-141.321-38.228a281.39 281.39 0 0 1-100.45-100.45 278.635 278.635 0 0 1-22.658-49.019C211.94 793.113 188 831.76 188 876a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8c0-79.295 48.07-147.363 116.658-176.654A284.122 284.122 0 0 1 232 680v-96H84a8 8 0 0 1-8-8v-56a8 8 0 0 1 8-8h148V412c-76.768 0-139-62.232-139-139a8 8 0 0 1 8-8h60a8 8 0 0 1 8 8c0 34.794 28.206 63 63 63h560c34.794 0 63-28.206 63-63a8 8 0 0 1 8-8h60a8 8 0 0 1 8 8c0 76.768-62.232 139-139 139v100h148a8 8 0 0 1 8 8v56a8 8 0 0 1-8 8H792zM368 272a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8c0-40.039 8.779-76.746 25.9-108.068 17.235-31.526 42.506-56.797 74.032-74.031C427.254 72.779 463.962 64 504 64h16c40.039 0 76.746 8.779 108.068 25.9 31.526 17.235 56.797 42.506 74.031 74.032C719.221 195.254 728 231.962 728 272a8 8 0 0 1-8 8h-56a8 8 0 0 1-8-8c0-28.326-5.938-53.154-17.077-73.531-10.625-19.437-25.955-34.767-45.392-45.392C573.154 141.937 548.326 136 520 136h-16c-28.326 0-53.154 5.938-73.531 17.077-19.437 10.625-34.767 25.955-45.392 45.392C373.937 218.846 368 243.674 368 272z"
                                        p-id="3616"></path>
                                    <path
                                        d="M308 412v268c0 36.779 9.678 71.962 27.805 102.907a205.39 205.39 0 0 0 73.288 73.288C440.038 874.322 475.22 884 512 884c36.779 0 71.962-9.678 102.907-27.805a205.39 205.39 0 0 0 73.288-73.288C706.322 751.962 716 716.78 716 680V412H308z"
                                        fill="#E6E6E6" p-id="3617"></path>
                                </svg>
                            </i>
                            BUG反馈
                        </div>
                    </router-link>
                </el-menu-item>
            </el-menu>
        </div>
    </el-row>
</template>
  
<script>

import {
    Document,
    Menu as IconMenu,
    Location,
    Setting,
} from '@element-plus/icons-vue'

export default {
    setup() {
        const handleOpen = (key, keyPath) => {
            console.log(key, keyPath)
        }
        const handleClose = (key, keyPath) => {
            console.log(key, keyPath)
        }

        return {
            handleOpen,
            handleClose,
            Document,
            IconMenu,
            Location,
            Setting
        }
    }
}
</script>
  
<style scoped>
@font-face {
    font-family: 阿里妈妈刀隶体 Regular;
    src: url('../assets/font/AlimamaDaoLiTi.ttf');
}

.total {
    float: left;
    width: 200px;
    font-family: 阿里妈妈刀隶体 Regular;
}

.big {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.total {
    position: relative;
    height: 92vh;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
  