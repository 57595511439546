import { GameObject } from "./GameObject";

export class Wall extends GameObject {
    /**
     * r 和 c 是墙在地图中的行和列索引，
     * 用于确定墙在地图中的位置。
     * gamemap 是一个指向 GameMap 对象的引用，
     * 用于获取地图的相关信息和绘图上下文。
     */
    constructor(r, c, gamemap) {
        super();

        this.r = r;
        this.c = c;
        this.gamemap = gamemap;
        this.color = "#B37226";
    }

    update() {
        this.render();
    }

    /**
     * ctx.fillStyle 属性来设置填充颜色为 this.color
     * ctx.fillRect 方法绘制一个矩形，表示墙的形状和位置
     */
    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        ctx.fillStyle = this.color;
        ctx.fillRect(this.c * L, this.r * L, L, L);
    }
}