

export default {
    state: {
        id: "",
        user_id: "",
        title: "",
        content: "",
        create_time: "",
        modify_time: "",
        like_num: "",
        comment_num: "",
        user_nickname: "",
        user_photo: "",
    },
    getters: {
    },
    mutations: {
        // 清除缓存
        clean_postadd() {
            localStorage.removeItem("id");
            localStorage.removeItem("user_id");
            localStorage.removeItem("title");
            localStorage.removeItem("content");
            localStorage.removeItem("create_time");
            localStorage.removeItem("modify_time");
            localStorage.removeItem("like_num");
            localStorage.removeItem("comment_num");
            localStorage.removeItem("user_nickname");
            localStorage.removeItem("user_photo");
        },

        // 同步更新state书本信息
        updateBook(state, post) {
            state.id = post.id;
            state.user_id = post.user_id;
            state.title = post.title;
            state.content = post.content;
            state.create_time = post.create_time;
            state.modify_time = post.modify_time;
            state.like_num = post.like_num;
            state.comment_num = post.comment_num;
            state.user_nickname = post.user_nickname;
            state.user_photo = post.user_photo;
        },
    },
    actions: {
    },
    modules: {
    }
}