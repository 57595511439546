import { GameObject } from "./GameObject";
import { Wall } from "./Wall";
import { ChessGo } from "./ChessGo"
export class GameMap extends GameObject {
    constructor(ctx, parent) {
        super();

        this.ctx = ctx;
        this.parent = parent;

        this.L = 0;
        this.rows = 20;
        this.cols = 20;

        this.walls = [];

        this.Go = new ChessGo(this);


        this.ctx.canvas.addEventListener("mousedown", this.handleMouseDown.bind(this));

        this.clickChess = [];
    }
    handleMouseDown(event) {
        const rect = this.ctx.canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        const col = Math.floor(mouseX / this.L);
        const row = Math.floor(mouseY / this.L);

        const x = col * this.L;
        const y = row * this.L;

        //增强棋子的精确性
        const epsilon = 0.4;

        if (Math.floor(Math.abs(mouseX - x - this.L)) / this.L <
            epsilon && Math.floor(Math.abs(mouseY - y - this.L)) / this.L < epsilon
        ) {
            this.Go.addChess(row, col);
        }
    }

    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));

        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    create_walls() {
        /**
         * 定义一个数组，当值为true时，就表示为墙，
         * 进行染色。
         */
        const g = [];
        for (let r = 0; r < this.rows; r++) {
            g[r] = [];
            for (let c = 0; c < this.cols; c++) {
                g[r][c] = false;
            }
        }

        /**
         * 找地图的左右边界
         */
        for (let r = 0; r < this.rows; r++)
            g[r][0] = g[r][this.cols - 1] = true;

        /**
         * 找地图的上下边界
         */
        for (let c = 0; c < this.cols; c++)
            g[0][c] = g[this.rows - 1][c] = true;

        /**
         * 调用墙的类，对地图的边界进行染色
         */
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if (g[r][c]) {
                    this.walls.push(new Wall(r, c, this));//直接调用墙类
                }
            }
        }
    }

    start() {
        this.create_walls();
    }

    update() {
        this.update_size();
        this.render();
    }

    render() {
        const color_even = "#AAD751", color_odd = "#A2D149";
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }
    }
}