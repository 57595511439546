<template>
    <div class="container">
        <div class="row" style="margin-top: 20px;">
            <div class="col-8">
                <el-row>
                    <el-col :xs="4" :offset="8" :sm="4" :pull="pullValue" :push="pushValue">
                        <img :src="article.photo" alt="头像" class="author_photo__img" @click="jumpUser(article.nickName)"
                            style="cursor: pointer;">
                    </el-col>
                    <el-col :span="12">
                        <el-row>
                            <el-col>
                                {{ article.nickName }}
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col>
                                <button v-if="article.userId != $store.state.user.id" class="guanzhu guanzhu_lg">
                                    <span v-if="attention.status === 'true'" @click="removeAttention()">已关注</span>
                                    <span v-else @click="addAttention()">关注</span>
                                </button>
                                <span v-if="article.userId == $store.state.user.id"
                                    style="font-size: 15px; color: gray;">作者竟是我自己</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="8">
                                <img src="../../assets/images/评论1.png" alt="" style="width: 30px; height: 30px;">
                                <span class="span">{{ article.commentNum }}</span>
                            </el-col>
                            <el-col :span="8" :pull="pullValue">
                                <img src="../../assets/images/点击.png" alt="" style="width: 30px;height: 30px;">
                                <span class="span">{{ article.clickRate }}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <div class="card article_container">
                    <div class="post_container" style="padding: 40px 50px;">
                        <div class="header">
                            <div class="post_title">
                                {{ article.title }}
                            </div>
                            <div class="post_date">
                                {{ article.createTime }}
                            </div>
                        </div>
                        <div class="content" style="margin-top: 25px;">
                            <v-md-preview :text="text"></v-md-preview>
                        </div>
                    </div>
                </div>
                <div id="reply" name="reply" class="reply">
                    <div class="card" style="margin-top: 20px; margin-bottom: 20px; padding: 30px 50px;">
                        <div class="tip">看帖是喜欢，评论才是真爱：</div>
                        <el-input v-model="input" :rows="4" maxlength="10000" show-word-limit type="textarea"
                            placeholder="请开始你的表演…" />
                        <div style="text-align: right; margin-top: 20px;">
                            <el-button type="primary" @click="addComment">评论</el-button>
                        </div>
                    </div>
                </div>
                <div class="mycard">
                    <div v-for="(comment, i) in comments" :key="i" class="comment">
                        <div class="comment-left">
                            <div class="reader_photo">
                                <img :src="comment.userPhoto" alt="头像" class="reader_photo__img"
                                    @click="jumpUser(comment.userNickname)" style="cursor: pointer;">
                            </div>
                        </div>
                        <div class="comment-main" style="width: 100%;">
                            <div class="main_header">
                                <div class="main_title">
                                    <span class="comment-usernickname">{{ comment.userNickname }}</span>
                                    <span class="comment-time">{{ comment.time }}</span>
                                </div>
                                <div class="operation">
                                    <div v-if="(comment.userId == $store.state.user.id || $store.state.user.root === '1')">
                                        <el-dropdown>
                                            <el-icon>
                                                <More />
                                            </el-icon>
                                            <template #dropdown>
                                                <el-dropdown-menu>
                                                    <el-dropdown-item
                                                        @click="confirmedDelete(comment.id, comment.userId)">删除</el-dropdown-item>
                                                </el-dropdown-menu>
                                            </template>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div class="main_content">
                                <p>{{ comment.content }}</p>
                            </div>
                            <div class="main_bottom">
                                <div class="row">
                                    <div class="col-1">
                                        <div class="comment_zan">

                                            <el-button @click="add_good(comment.id, comment.bad)" link>
                                                <img src="../../assets/images/赞.png" alt=""
                                                    style="width: 20px;height: 20px;">
                                            </el-button>
                                            <span>{{ comment.good }}</span>
                                        </div>
                                    </div>
                                    <div class="col-1">
                                        <div class="comment_zan">
                                            <el-button @click="add_bad(comment.id, comment.good)" link>
                                                <img src="../../assets/images/踩.png" alt=""
                                                    style="width: 20px;height: 20px;">
                                            </el-button>
                                            <span>{{ comment.bad }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</template>

<script>
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import $ from 'jquery'
import { More } from '@element-plus/icons';
import { ElMessage, ElMessageBox } from 'element-plus';
import router from '@/router';
export default {
    name: "interaction_post",
    components: {
        More,
    },
    setup() {
        const route = useRoute()
        const store = useStore()
        let article_id = route.query.id     // 获取传过来的参数帖子的id
        let article = ref([])              // 帖子信息
        let input = ref('')             // 评论框
        let comments = ref([])           // 评论

        let good = ref('')
        let bad = ref('')
        let comment_id = ref('')
        let statu = ref('')
        let attention = ref([])
        const profile = reactive({
            error_message: '',
        })
        const text = ref('');
        const pushValue = ref(0)
        const pullValue = ref(0)
        // 获取帖子信息
        const getOneArticle = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/get/one/article/",
                type: "post",
                data: {
                    article_id: article_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    article.value = resp;
                    text.value = article.value.content;
                }
            })
        }
        getOneArticle();

        // 获取评论
        const getComment = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/get/article/comment/",
                type: "post",
                data: {
                    article_id: article_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    comments.value = resp;
                }
            })
        }

        getComment();

        // 发布评论
        const addComment = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/article/comment/",
                type: "post",
                data: {
                    article_id: article_id,
                    content: input.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {

                        getComment();
                        profile.error_message = resp.error_message;
                        add_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }


        // 评论成功提示
        const add_success = () => {
            ElMessage({
                message: '评论成功~',
                type: 'success',
            })
            getOneArticle();
            input.value = "";
        }

        // 删除前弹出询问
        const confirmedDelete = (comment_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deleteComment(comment_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除评论
        const deleteComment = (comment_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/delete/article/comment/",
                type: "post",
                data: {
                    article_id: article_id,
                    comment_id: comment_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {

                        getComment();
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 删除成功提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            getOneArticle();
        }

        // 操作失败提示
        const error = () => {
            ElMessage.error(profile.error_message)
        }
        // 点赞
        const add_good = (id, bads) => {
            comment_id.value = id;
            good.value = 1;
            if (bads > 1) {
                bad.value = -1;
            } else {
                bad.value = 0;
            }
            comment_like();
        }
        const add_bad = (id, goods) => {
            comment_id.value = id;
            bad.value = 1;
            if (goods > 1) {
                good.value = -1;
            } else {
                good.value = 0;
            }
            comment_like();
        }
        const comment_like = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/article/comment/like/",
                type: "post",
                data: {
                    comment_id: comment_id.value,
                    good: good.value,
                    bad: bad.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success() {
                    getComment();
                }
            })
        }
        /*关注~~start*/
        const addAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/attention/",
                type: "post",
                data: {
                    author_name: article.value.nickName,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(article.value.nickName);
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_attention_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_attention_error();
                    }
                }
            })
        }
        // 成功提示
        const add_attention_success = () => {
            ElMessage({
                message: '关注成功~',
                type: 'success',
            })
            getOneArticle();
            statusAttention();
        }

        // 失败提示
        const add_attention_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*关注~~end*/

        /*关注状态~~start*/
        const statusAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/get/one/attention/",
                type: "post",
                data: {
                    article_id: article_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    attention.value = resp;
                }
            })
        }
        statusAttention();
        statu.value = attention.value.status;
        /*关注状态~~end*/

        /**取消关注~~start*/
        const removeAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/remove/attention/",
                type: "post",
                data: {
                    author_name: article.value.nickName,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(article.value.nickName);
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        remove_attention_success();
                    } else {
                        profile.error_message = resp.error_message;
                        remove_attention_error();
                    }
                }
            })
        }
        // 成功提示
        const remove_attention_success = () => {
            ElMessage({
                message: '取消关注成功~',
                type: 'success',
            })
            getOneArticle();
            statusAttention();
        }

        // 失败提示
        const remove_attention_error = () => {
            ElMessage.error(profile.error_message)
        }
        /**取消关注~~end*/

        const jumpUser = (username) => {
            router.push({
                name: "information_view",
                path: "InformationView",
                query: {
                    username: username
                }
            })
        }
        /**
         * 媒体查询控制格子
         */

        const handleResize = () => {
            if (window.innerWidth < 550) {
                pullValue.value = 2
            } else if (window.innerWidth > 550 && window.innerWidth < 990) {
                pushValue.value = 0
            } else if (window.innerWidth > 990) {
                pushValue.value = 2
                pullValue.value = 6
            } else {
                pushValue.value = 0
            }
        }

        onMounted(() => {
            handleResize();
            window.addEventListener('resize', handleResize)
        })

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize)
        })
        return {
            article_id,
            article,
            input,
            comments,
            profile,
            getOneArticle,
            comment_like, getComment, add_bad, add_good, confirmedDelete,
            add_success, addComment, addAttention, add_attention_success,
            add_attention_error, attention, statusAttention, statu, removeAttention,
            good, bad, comment_id, error, delete_success, deleteComment, text, jumpUser,
            pushValue, handleResize, pullValue

        }
    }
}
</script>

<style scoped>
.item {
    cursor: pointer;
    padding: 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.item .el-icon {
    font-size: 30px;
    color: #b3b3b3;
}

.item .span {
    color: #b3b3b3;
}

.header {
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.post_title {
    font-size: 40px;
    font-weight: 600;
    line-height: 26px;
    display: block;
    margin: 0;
}

.post_date {
    text-align: center;
    font-size: 15px;
    color: rgba(111, 110, 110, 0.534);
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    float: none;
}

.post_date span {
    font-size: 15px;
    padding: 0 15px;
    line-height: 18px;
}

.tip {
    color: #ccc;
    margin-bottom: 15px;
}

.author {
    padding: 0 50px;
    height: 160px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
}

.author_img {
    float: left;
}

.author_photo__img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    vertical-align: top;
}

.author_name {
    font-size: 20px;
    padding-left: 30px;
    width: 200px;
    text-align: center;
}

.author_like {
    text-align: center;
    margin-top: 10px;
    width: 200px;
}

.guanzhu {
    background-color: #00c3ff;
    border-radius: 13px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    width: 70px;
    display: inline-block;
    color: #fff;
    text-align: center;
    cursor: pointer;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-transition: .2s background-color;
    -o-transition: .2s background-color;
    transition: .2s background-color;
}

.guanzhu_lg {
    width: 86px;
    height: 34px;
    line-height: 32px;
    background-color: #fff;
    color: #00b2ff;
    border: 1px solid #00c3ff;
    border-radius: 4px;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-property: border-color, color;
    -o-transition-property: border-color, color;
    transition-property: border-color, color;
}

.mycard {
    background-color: #fff;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.175);
    border: var(border-width) solid var(border-color);
}

.comment {
    display: flex;
    flex-direction: row;
    padding: 24px 50px;
    overflow: visible;
    background-color: #fff;
    border-top: 1px solid #ebebebc5;
}

.reader_photo {
    width: 44px;
    height: 44px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.reader_photo__img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #ebebeb;
    vertical-align: top;
}

.main_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2px;
}

.main_title {
    display: flex;
    flex-direction: column;
}

.comment-usernickname {
    color: #666;
    font-size: 14px;

}

.comment-time {
    color: #ccc;
    font-size: 12px;
}

.main_content {
    padding: 20px 0;
    word-wrap: break-word;
    word-break: break-word;
    font-size: 14px;
    line-height: 24px;
    color: #666;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-family: inherit;
    font-size: 1em;
}

.main_bottom {
    color: #b3b3b3;
}

.comment_zan {
    display: flex;
    flex-direction: row;
}

@media screen and (min-width: 200px) and (max-width: 780px) {

    .col-8 {
        width: 100%;
        padding: 0px;
    }

    .post_container {
        padding: 40px 0px !important;
    }

    .article_container {
        margin-top: 10px;
    }

    .content {
        margin-top: 0px !important;
    }
}

@media screen and (min-width: 780px) {
    .col-8 {
        width: 70%;
        margin-left: 15%;
        padding: 0px;
    }

    .post_container {
        padding: 40px 0px !important;
    }

    .article_container {
        margin-top: 10px;
    }

    .content {
        margin-top: 0px !important;
    }
}
</style>