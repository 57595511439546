<template>
    <div v-mouse-move-effect>
        <el-row class="tac">
            <div class="centered-text">
                <el-menu class="el-menu-vertical-demo">
                    <el-menu-item index="1" @click="select_articles('数据库')" class="big">
                        <img src="../../assets/images/数据库.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>数据库</span>
                    </el-menu-item>
                    <el-menu-item index="2" @click="select_articles('工具')" class="big">
                        <img src="../../assets/images/学习中心.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>工具</span>
                    </el-menu-item>
                    <el-menu-item index="3" @click="select_articles('服务器')" class="big">
                        <img src="../../assets/images/科技.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>服务器</span>
                    </el-menu-item>
                    <el-menu-item index="4" @click="select_articles('前端')" class="big">
                        <img src="../../assets/images/WEB前端.png" alt=""
                            style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>前端</span>
                    </el-menu-item>
                    <el-menu-item index="5" @click="select_articles('后端')" class="big">
                        <img src="../../assets/images/后端开发.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>后端</span>
                    </el-menu-item>
                    <el-menu-item index="6" @click="select_articles('语言')" class="big">
                        <img src="../../assets/images/语言.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>语言</span>
                    </el-menu-item>
                    <el-menu-item index="7" @click="select_articles('算法')" class="big">
                        <img src="../../assets/images/算法.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>算法</span>
                    </el-menu-item>
                    <el-menu-item index="8" @click="select_articles('计组')" class="big">
                        <img src="../../assets/images/算法.png" alt="" style="width: 25px;height: 25px;padding-right: 5px;">
                        <span>计组</span>
                    </el-menu-item>
                </el-menu>
            </div>
        </el-row>
        <div class="container">
            <div class="row justify-content-center">
                <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                    <div class="card-body">
                        <el-input v-model="search" placeholder="您想要搜索的作品名" class="input-with-select">
                            <template #append>
                                <el-button @click="handleSearch">
                                    <el-icon>
                                        <Search />
                                    </el-icon>
                                </el-button>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-container">
            <div v-for="item in filteredTableData" :key="item" class="card-item">
                <el-card class="hover-pointer">
                    <div class="card-header">
                        <div class="card-title" @click="jumpArticle(item.id)">{{ item.title }}</div>
                        <div style="text-align: center;font-size: 10px;">{{ item.createTime }}</div>
                    </div>
                    <div class="card-dropdown" v-if="$store.state.user.root === '1'" style="text-align: right;">
                        <el-dropdown>
                            <el-icon>
                                <More />
                            </el-icon>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="confirmedDelete(item.id, item.userId)">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <hr>
                    <div style="text-align: center;font-size: 20px;">作者: {{ item.nickName }}</div>
                    <div style="text-align: center;font-size: 20px;">点击量: {{ item.clickRate }}</div>
                    <div style="display: flex; justify-content: center;">
                        <el-button type="primary" @click="collectArticle(item.id)">收藏</el-button>
                    </div>
                </el-card>
            </div>
            <div class="page"
                style="position: fixed; bottom: 10vh; left: 50%; transform: translateX(-50%); background-color: white; padding: 10px;">
                <el-pagination v-model:current-page="currentPage" :page-size="6" :small="small" background
                    layout="total, prev, pager, next" :total="total" @current-change="changePostpage" />
            </div>
        </div>
    </div>
</template>
  
<script>
import { reactive, ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import $ from 'jquery';
import { useStore } from 'vuex';
import { Search } from '@element-plus/icons';
import { More } from '@element-plus/icons';
export default {
    components: {
        Search,
        More
    },
    setup() {
        const store = useStore();
        const addDrawer = ref(false);

        let classify = ref('')
        let content = ref('')

        let currentPage = ref(1)
        let article_id = ref('')
        let total = ref('')
        let classify_page = ref('')
        let tableData = ref([])
        let allData = ref([]);
        const profile = reactive({
            error_message: '',
        })
        // 搜索栏检索书籍
        const search = ref('')
        /*小屏全部文章~~start*/
        const getAll = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/get/all/article/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    allData.value = resp;
                }
            })
        }
        getAll();

        const filteredTableData = computed(() => {
            if (window.innerWidth < 768) {
                return allData.value;
            } else {
                return tableData.value;
            }
        });

        const handleResize = () => {
            // 强制更新计算属性
            filteredTableData.value;
        };

        onMounted(() => {
            window.addEventListener('resize', handleResize);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('resize', handleResize);
        });
        /*小屏全部文章*/
        const getPostTotal = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/page/get/article/",
                type: "post",
                data: {
                    classify: classify_page.value,
                    search: search.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    total.value = resp;
                },
                error() {
                    profile.error_message = "没有数据哦！";
                    page_error();
                }
            })
        }
        getPostTotal();

        const getPostPageOne = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/page/get/page/article/",
                type: "post",
                data: {
                    page: 1,
                    classify: classify_page.value,
                    search: search.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    currentPage.value = 1;
                    tableData.value = resp;
                    allData.value = resp;
                },
                error() {
                    profile.error_message = "没有数据哦！";
                    page_error();
                }
            })
        }

        getPostPageOne();
        const changePostpage = (number) => {
            $.ajax({
                url: " https://www.scqblog.com/api/page/get/page/article/",
                type: "post",
                data: {
                    page: number,
                    classify: classify_page.value,
                    search: search.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableData.value = resp;
                }
            })
        }
        const page_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*全部文章~~end*/
        const handleSearch = () => {
            classify_page.value = "";
            getPostTotal();
            getPostPageOne();
        }
        /*分类文章~~start*/
        const select_articles = (keyword) => {
            classify_page.value = keyword;
            search.value = "";
            getPostTotal();
            getPostPageOne();
        }
        /*分类文章~~end*/


        // 选择器
        const options = [
            {
                label: '一类',
                options: [
                    {
                        value: '数据库',
                        label: '数据库',
                    },
                    {
                        value: '工具',
                        label: '工具',
                    },
                    {
                        value: '服务器',
                        label: '服务器',
                    },
                ],
            },
            {
                label: '二类',
                options: [
                    {
                        value: '前端',
                        label: '前端',
                    },
                    {
                        value: '后端',
                        label: '后端',
                    },
                    {
                        value: '算法',
                        label: '算法',
                    },
                    {
                        value: '语言',
                        label: '语言',
                    },
                    {
                        value: '计组',
                        label: '计组',
                    },
                ],
            }
        ]



        // 打开原文页面
        const jumpArticle = (id) => {
            article_id.value = id;
            addClick();


            window.open(`/blog/article?id=${article_id.value}`, '_blank');
            localStorage.setItem('previousRoute', `/blog/article?id=${article_id.value}`);

        }
        /*点击量的增加~~start*/
        const addClick = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/click/rate/",
                type: "post",
                data: {
                    article_id: article_id.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_click_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_click_error();
                    }
                },
                error() {

                }

            })
        }
        const add_click_success = () => {
            ElMessage({
                message: '~点击量+1~',
                type: 'success',
            })
            getPostPageOne();
        }
        const add_click_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*点击量的增加~~end*/

        /*收藏~~start*/
        const collectArticle = (id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/collect/",
                type: "post",
                data: {
                    article_id: id,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_collect_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_collect_error();
                    }
                },
                error() {

                }

            })
        }
        const add_collect_success = () => {
            ElMessage({
                message: '~收藏成功~',
                type: 'success',
            })
            getPostPageOne();
        }
        const add_collect_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*收藏~~end*/

        /*文章的删除~~start*/
        // 删除前弹出询问
        const confirmedDelete = (article_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deleteArticle(article_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除文章
        const deleteArticle = (article_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/remove/article/",
                type: "post",
                data: {
                    id: article_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }
        const error = () => {
            ElMessage.error(profile.error_message)
        }
        // 删除成功提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            getPostTotal();
            getPostPageOne();
            changePostpage();
        }
        /*文章的删除~~end*/
        return {
            store, addDrawer, profile, classify, content,
            options, tableData, page_error,
            search, select_articles, jumpArticle, article_id, handleSearch,
            add_click_success, add_click_error, addClick, collectArticle, add_collect_success, add_collect_error,
            getPostPageOne, changePostpage, currentPage, total, getPostTotal, classify_page, confirmedDelete,
            delete_success, deleteArticle, error, getAll, allData, filteredTableData, handleResize
        }
    }

}
</script>

<style scoped>
@font-face {
    font-family: 阿里妈妈刀隶体 Regular;
    src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.container {
    font-family: 阿里妈妈刀隶体 Regular;
}

.tac {
    float: left;
    width: 200px;
    position: relative;
    height: 92vh;
    font-family: 阿里妈妈刀隶体 Regular;
}

.big {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    /* 负值的间距，用于抵消卡片的间距 */
    padding-top: 10px;
    font-family: 阿里妈妈刀隶体 Regular;
}

.card-item {
    flex: 0 0 calc(33.3333% - 20px);
    /* 设置卡片的宽度，这里假设每行显示3个卡片 */
    margin: 10px;
    /* 设置卡片之间的间距 */
}

.hover-pointer {
    cursor: pointer;
    /* 设置鼠标悬浮时显示小手指示符 */
}

.card-header {
    text-align: center;
    font-size: 20px;
}

.card-title {
    text-align: center;
    font-size: 25px;
}

@media screen and (max-width: 770px) {
    .centered-text {
        padding-bottom: 100px;
    }

    .tac,
    .page {
        display: none;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .card-item {
        flex-basis: 60%;
    }
}
</style>
  