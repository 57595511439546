<template>
    <el-row class="tac">
        <div class="centered-text">
            <el-menu class="el-menu-vertical-demo">
                <el-menu-item index="1" @click="refresh_games" class="big">
                    <span>全部</span>
                </el-menu-item>
                <el-menu-item index="2" @click="select_games('手速')" class="big">
                    <span>手速</span>
                </el-menu-item>
                <el-menu-item index="3" @click="select_games('动作')" class="big">
                    <span>动作</span>
                </el-menu-item>
                <el-menu-item index="4" @click="select_games('消除')" class="big">
                    <span>消除</span>
                </el-menu-item>
                <el-menu-item index="5" @click="select_games('单人')" class="big">
                    <span>单人</span>
                </el-menu-item>
                <el-menu-item index="6" @click="select_games('双人')" class="big">
                    <span>双人</span>
                </el-menu-item>
            </el-menu>
        </div>
    </el-row>
    <div class="container">
        <div class="row justify-content-center">
            <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                <div class="card-body">
                    <el-input v-model="search" placeholder="您想要搜索的小游戏名" class="input-with-select">
                        <template #append>
                            <el-button>
                                <el-icon>
                                    <Search />
                                </el-icon>
                            </el-button>
                        </template>
                    </el-input>
                    <div v-if="$store.state.user.root === '1'" @click="addDrawer_up">
                        <button>发布</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-drawer v-model="addDrawer" title="作品" direction="btt" :before-close="addHandleClose"
        style="width: 100vh; position: fixed;top: 50%; left: 50%; transform: translate(-50%, -50%);font-family: 阿里妈妈刀隶体 Regular;"
        size="75vh">
        <template #title>
            <div class="title_center">作品</div>
        </template>
        <div class="content_center">
            <el-input v-model="title" maxlength="20" show-word-limit placeholder="给你的作品起个标题吧~" />
        </div>
        <div class="content_center" style="padding: 5vh;">
            <el-input v-model="photo" maxlength="200" show-word-limit placeholder="给你的作品贴个图片吧~" />
        </div>
        <el-select v-model="classify" clearable placeholder="选择分类">
            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value" />
            </el-option-group>
        </el-select>
        <div class="content_center" style="display: flex;justify-content: center;align-items: center;">
            <el-button plain style="margin-right: 15px;" @click="saveGame()">保存</el-button>
            <el-button type="primary" @click="addGame">发布</el-button>
        </div>
    </el-drawer>
    <div class="card-container">
        <div v-for="(item, index) in filterTableData" :key="index" class="card-item">
            <el-card class="hover-pointer">
                <div class="card-header">
                    <div class="card-title">{{ item.title }}</div>
                    <div style="text-align: center;font-size: 10px;">{{ item.createTime }}</div>
                </div>
                <hr>
                <div style="text-align: center;font-size: 20px;">作者: 帅气的站长</div>
                <div style="display: flex; justify-content: center;">
                    <el-button type="primary" @click="jumpGame(item.id)">开始游戏</el-button>
                    <div v-if="$store.state.user.root === '1'">
                        <button @click="updateDrawer_up(item.id, item.classify, item.title, item.photo)">修改</button>
                    </div>
                    <div v-if="$store.state.user.root === '1'">
                        <button @click="confirmedDelete(item.id, item.userId)">删除</button>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
    <el-drawer v-model="updateDrawer" title="修改了" direction="btt" :before-close="updateHandleClose"
        style="width: 100vh; position: fixed;top: 50%; left: 50%; transform: translate(-50%, -50%);" size="75vh">
        <template #title>
            <div class="title_center">修改了</div>
        </template>
        <div class="content_center">
            <el-input v-model="game_update_title" maxlength="20" show-word-limit placeholder="给你的留言起个标题吧~" />
        </div>
        <div class="content_center" style="padding: 5vh;">
            <el-input v-model="game_update_photo" :rows="15" maxlength="2000" show-word-limit type="textarea"
                placeholder="说你所想~但要注意文明发言哦~" />
        </div>
        <el-select v-model="game_update_classify" clearable placeholder="选择分类">
            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value" />
            </el-option-group>
        </el-select>
        <div class="content_center" style="display: flex;justify-content: center;align-items: center;">
            <el-button type="primary" @click="updateGame()">发布</el-button>
        </div>
    </el-drawer>
</template>
  
<script>
import { reactive, ref, computed } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import $ from 'jquery';
import { useStore } from 'vuex';
import router from '@/router'; import { Search } from '@element-plus/icons';
export default {
    components: {
        Search
    },
    setup() {
        const store = useStore();
        const addDrawer = ref(false);
        const updateDrawer = ref(false)
        let classify = ref('')
        let photo = ref('')
        let title = ref('')

        let game_id = ref('')
        let game_update_classify = ref('')
        let game_update_title = ref('')
        let game_update_photo = ref('')
        let game_update_id = ref('')
        const profile = reactive({
            error_message: '',
        })
        // 搜索栏检索书籍
        const search = ref('')
        const filterTableData = computed(() =>
            tableData.value.filter(
                (data) =>
                    !search.value ||
                    data.title.toLowerCase().includes(search.value.toLowerCase())
            )
        )
        /*全部文章~~start*/
        let tableData = ref([])
        const refresh_games = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/get/all/game/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableData.value = resp;
                },
                error() {
                }
            })
        }

        refresh_games();
        /*全部文章~~end*/

        /*分类文章~~start*/
        const select_games = (keyword) => {
            $.ajax({
                url: " https://www.scqblog.com/api/get/list/game/",
                type: "get",
                data: {
                    classify: keyword,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    tableData.value = resp;
                },
                error() {
                }
            })
        }
        /*分类文章~~end*/

        /*文章的添加~~start*/
        const addDrawer_up = () => {
            addDrawer.value = true;
        }

        const addHandleClose = () => {
            ElMessageBox.confirm('没保存的内容会丢失哦，退出账号重新登录没发布的内容也会丢失哦，确定退出编辑吗？')
                .then(() => {
                    addDrawer.value = false;
                })
                .catch(() => {
                })
        }

        // 选择器
        const options = [
            {
                label: '一类',
                options: [
                    {
                        value: '手速',
                        label: '手速',
                    },
                    {
                        value: '动作',
                        label: '动作',
                    },
                    {
                        value: '消除',
                        label: '消除',
                    },
                ],
            },
            {
                label: '二类',
                options: [
                    {
                        value: '单人',
                        label: '单人',
                    },
                    {
                        value: '双人',
                        label: '双人',
                    },
                ],
            }
        ]

        const addGame = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/game/",
                type: "post",
                data: {
                    classify: classify.value,
                    title: title.value,
                    photo: photo.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_game_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_game_error();
                    }
                },
                error() {

                }

            })
        }

        const add_game_success = () => {
            ElMessage({
                message: '添加成功~',
                type: 'success',
            })
            addDrawer.value = false;

            if (classify.value === "") {
                refresh_games();
            } else {
                select_games(classify.value);
            }
            classify.value = "";
            photo.value = "";
            title.value = "";
            store.commit("clean_gameadd");
        }
        const add_game_error = () => {
            ElMessage.error(profile.error_message)
        }

        //保存模块
        const saveGame = () => {
            localStorage.setItem("title", title.value);
            localStorage.setItem("classify", classify.value);
            localStorage.setItem("photo", photo.value);
            ElMessage({
                message: '内容已保存',
                type: 'info',
            })
        }
        // 自动加载上次保存内容
        const save_load = () => {
            title.value = localStorage.getItem("title");
            classify.value = localStorage.getItem("classify");
            photo.value = localStorage.getItem("photo");
        }

        save_load();
        /*文章的添加~~end*/

        // 打开游戏页面
        const jumpGame = (id) => {
            if (parseInt(id) === 1) {
                router.push({ name: "kof_menu", })
            }
        }

        /*文章的修改~~start*/
        // 打开更新文章编辑窗口
        const updateDrawer_up = (id, classify, title, photo) => {
            updateDrawer.value = true;
            game_update_classify.value = classify;
            game_update_title.value = title;
            game_update_photo.value = photo;
            game_update_id.value = id;
        }

        // 关闭改帖窗口前弹出询问
        const updateHandleClose = () => {
            ElMessageBox.confirm('确定不更改了吗？')
                .then(() => {
                    updateDrawer.value = false;
                    game_update_classify.value = '';
                    game_update_title.value = '';
                    game_update_photo.value = '';
                    game_update_id.value = '';
                })
                .catch(() => {
                })
        }

        // 修改帖子
        const updateGame = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/rewrite/game/",
                type: "post",
                data: {
                    game_id: game_update_id.value,
                    classify: game_update_classify.value,
                    title: game_update_title.value,
                    photo: game_update_photo.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        update_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 修改成功提示
        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
            updateDrawer.value = false;
            refresh_games();
            game_update_classify.value = '';
            game_update_title.value = "";
            game_update_photo.value = "";
            game_update_id.value = "";
        }
        // 操作失败提示
        const error = () => {
            ElMessage.error(profile.error_message)
        }
        /*文章的修改~~end*/

        /*文章的删除~~start*/
        // 删除前弹出询问
        const confirmedDelete = (game_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deleteGame(game_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除文章
        const deleteGame = (game_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/remove/game/",
                type: "post",
                data: {
                    id: game_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 删除成功提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            refresh_games();
        }
        /*文章的删除~~end*/


        return {
            store, addDrawer, addDrawer_up, addHandleClose, profile, classify, photo, title,
            options, addGame, add_game_success, add_game_error, tableData, refresh_games,
            filterTableData, search, select_games, saveGame, save_load, jumpGame, game_id,
            updateDrawer, game_update_classify, game_update_title, game_update_photo, game_update_id,
            updateDrawer_up, updateHandleClose, updateGame, update_success, error, confirmedDelete,
            deleteGame, delete_success,
        }
    }

}
</script>

<style scoped>
@font-face {
    font-family: 阿里妈妈刀隶体 Regular;
    src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.container {
    font-family: 阿里妈妈刀隶体 Regular;
}

.tac {
    float: left;
    width: 200px;
    position: relative;
    height: 92vh;
    font-family: 阿里妈妈刀隶体 Regular;
}

.big {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    /* 负值的间距，用于抵消卡片的间距 */
    padding-top: 10px;
    font-family: 阿里妈妈刀隶体 Regular;
}

.card-item {
    flex: 0 0 calc(33.3333% - 20px);
    /* 设置卡片的宽度，这里假设每行显示3个卡片 */
    margin: 10px;
    /* 设置卡片之间的间距 */
}

.add-news-text-field {
    background-color: #F0F2F5;
    cursor: pointer;
    height: 45px;
    border-radius: 50px;
    padding: 10px 10px 10px 15px;
    font-size: 20px;
    color: #656768;
}

.hover-pointer {
    cursor: pointer;
    /* 设置鼠标悬浮时显示小手指示符 */
}

.card-header {
    text-align: center;
    font-size: 20px;
}

.card-title {
    text-align: center;
    font-size: 25px;
}

.title_center {
    text-align: center;
    font-size: 60px;
    font-family: 阿里妈妈刀隶体 Regular;
}
</style>
  