<template>
    <MySpace />
    <div class="container" style="font-family: 阿里妈妈刀隶体 Regular; font-size: 30px;">
        <div class="row justify-content-center">
            <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                <div class="card-body" style="display: flex; align-items: center; justify-content: center;">
                    <div class="photo">
                        <img :src="$store.state.user.photo" alt="头像" class="photo_img">
                    </div>
                    <div class="add-news-text-field text-center" @click="addDrawer_up" style="text-align: center;">
                        {{ $store.state.user.nickname }}，快分享你的作品吧!
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-drawer v-model="addDrawer" title="作品" direction="btt" :before-close="addHandleClose" style="width: 140vh; position: fixed;top: 50%; left: 50%; transform: translate(-50%, -50%);
        font-family: 阿里妈妈刀隶体 Regular; font-size: 60px;" size="100%">
        <template #title>
            <div class="title_center">作品</div>
        </template>
        <div class="content_center">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-input v-model="title" maxlength="20" show-word-limit placeholder="给你的作品起个标题吧~"
                    style="width: 30vh; text-align: center;" />
            </div>
        </div>
        <div class="content_center" style="padding-top: 20px;">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-select v-model="classify" clearable placeholder="选择分类">
                    <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-option-group>
                </el-select>
            </div>
        </div>
        <div class="content_center" style="padding: 5vh;">
            <v-md-editor v-model="text" height="1000px" @save="save()" :include-level="[1, 2, 3, 4, 5]"
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji | tip| todo-list"
                right-toolbar="preview toc sync-scroll fullscreen" :disabled-menus="[]" @upload-image="handleUploadImage"
                accept="image/*">
            </v-md-editor>
        </div>
        <div class="content_center" style="display: flex;justify-content: center;align-items: center;">
            <el-button plain style="margin-right: 15px;" @click="save()">保存</el-button>
            <el-button type="primary" @click="addArticle">发布</el-button>
        </div>
    </el-drawer>

    <div class="card-container">
        <div v-for="item in myArticles" :key="item" class="card-item">
            <el-card style="font-size: 20px" class="hover-pointer">
                <template #header>
                    <div class="card-header">
                        <div class="card-title" @click="jumpArticle(item.id)">{{ item.title }}</div>
                    </div>
                    <div class="card-time">{{ item.createTime }}</div>
                    <div class="card-dropdown">
                        <el-dropdown>
                            <el-icon>
                                <More />
                            </el-icon>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item
                                        @click="updateDrawer_up(item.id, item.classify, item.title, item.content)">修改</el-dropdown-item>
                                    <el-dropdown-item @click="confirmedDelete(item.id, item.userId)">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </template>
                <div style="text-align: center;">作者: {{ item.nickName }}</div>
                <div style="text-align: center;">点击量: {{ item.clickRate }}</div>
            </el-card>
        </div>
        <div class="page"
            style="position: fixed; bottom: 10vh; left: 50%; transform: translateX(-50%); background-color: white; padding: 10px;">
            <el-pagination v-model:current-page="currentPage" :page-size="6" :small="small" background
                layout="total, prev, pager, next" :total="total" @current-change="changePostpage" />
        </div>
    </div>

    <el-drawer v-model="updateDrawer" title="修改了" direction="btt" :before-close="updateHandleClose"
        style="width: 140vh; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);font-family: 阿里妈妈刀隶体 Regular; font-size: 60px;"
        size="100%">
        <template #title>
            <div class="title_center">修改了</div>
        </template>
        <div class="content_center">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-input v-model="article_update_title" maxlength="20" show-word-limit placeholder="给你的留言起个标题吧~"
                    style="width: 30vh; text-align: center;" />
            </div>
        </div>
        <div class="content_center" style="padding-top: 20px;">
            <div style="display: flex; justify-content: center; align-items: center;">
                <el-select v-model="article_update_classify" clearable placeholder="选择分类">
                    <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                        <el-option v-for="item in group.options" :key="item.value" :label="item.label"
                            :value="item.value" />
                    </el-option-group>
                </el-select>
            </div>
        </div>
        <div class="content_center" style="padding: 5vh;">
            <v-md-editor v-model="article_update_content" height="1000px" :include-level="[1, 2, 3, 4, 5]"
                left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | emoji| tip| todo-list"
                right-toolbar="preview toc sync-scroll fullscreen" :disabled-menus="[]" @upload-image="handleUploadImage"
                accept="image/*"></v-md-editor>
        </div>
        <div class="content_center" style="display: flex; justify-content: center; align-items: center;">
            <el-button type="primary" @click="updateArticle()">发布</el-button>
        </div>
    </el-drawer>
</template>
  
<script>
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import $ from 'jquery';
import { useStore } from 'vuex';
import MySpace from '../../../../components/MySpace.vue'
import router from '@/router';
import { More } from '@element-plus/icons';
export default {
    components: {
        MySpace,
        More
    },
    setup() {
        const store = useStore();
        const addDrawer = ref(false);

        let classify = ref('')
        let content = ref('')
        let title = ref('')
        let myArticles = ref([])
        let text = ref('')
        let total = ref('')
        let currentPage = ref(1)
        let article_update_classify = ref('')
        let article_update_title = ref('')
        let article_update_content = ref('')
        let article_update_id = ref('')
        const updateDrawer = ref(false)

        let article_id = ref('')

        const profile = reactive({
            error_message: '',
        })
        /*文章的添加~~start*/
        const addDrawer_up = () => {
            addDrawer.value = true;
        }

        const addHandleClose = () => {
            ElMessageBox.confirm('没保存的内容会丢失哦，退出账号重新登录没发布的内容也会丢失哦，确定退出编辑吗？')
                .then(() => {
                    addDrawer.value = false;
                })
                .catch(() => {
                })
        }

        // 选择器
        const options = [
            {
                label: '一类',
                options: [
                    {
                        value: '数据库',
                        label: '数据库',
                    },
                    {
                        value: '工具',
                        label: '工具',
                    },
                    {
                        value: '服务器',
                        label: '服务器',
                    },
                ],
            },
            {
                label: '二类',
                options: [
                    {
                        value: '前端',
                        label: '前端',
                    },
                    {
                        value: '后端',
                        label: '后端',
                    },
                    {
                        value: '算法',
                        label: '算法',
                    },
                    {
                        value: '语言',
                        label: '语言',
                    },
                    {
                        value: '计组',
                        label: '计组',
                    },
                ],
            }
        ]

        const addArticle = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/article/",
                type: "post",
                data: {
                    classify: classify.value,
                    title: title.value,
                    content: text.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_article_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_article_error();
                    }
                },
                error() {

                }

            })
        }

        const add_article_success = () => {
            ElMessage({
                message: '添加成功~',
                type: 'success',
            })
            addDrawer.value = false;
            classify.value = "";
            text.value = "";
            title.value = "";
            myArticle();
            getPostTotal();
            changePostpage();
            store.commit("clean_articleadd");
        }
        const add_article_error = () => {
            ElMessage.error(profile.error_message)
        }

        //保存模块
        const save = () => {
            localStorage.setItem("title", title.value);
            localStorage.setItem("classify", classify.value);
            localStorage.setItem("content", text.value);
            ElMessage({
                message: '内容已保存',
                type: 'info',
            })
        }
        // 自动加载上次保存内容
        const save_load = () => {
            title.value = localStorage.getItem("title");
            classify.value = localStorage.getItem("classify");
            if (localStorage.getItem("content") != null) {
                text.value = localStorage.getItem("content");
            }
        }

        save_load();
        /*文章的添加~~end*/

        /*个人文章的获取~~start*/
        const myArticle = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/my/article/",
                type: "post",
                data: {
                    page: 1
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    myArticles.value = resp;
                    currentPage.value = 1;
                },
                error() {

                }

            })
        }
        myArticle();
        /*个人文章的获取~~end*/
        const getPostTotal = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/my/article/page/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    total.value = resp;
                },
            })
        }
        getPostTotal();

        const changePostpage = (number) => {
            $.ajax({
                url: " https://www.scqblog.com/api/my/article/",
                type: "post",
                data: {
                    page: number,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    myArticles.value = resp;
                }
            })
        }
        /*文章的修改~~start*/
        // 打开更新文章编辑窗口
        const updateDrawer_up = (id, classify, title, content) => {
            updateDrawer.value = true;
            article_update_classify.value = classify;
            article_update_title.value = title;
            article_update_content.value = content;
            article_update_id.value = id;
        }

        // 关闭改帖窗口前弹出询问
        const updateHandleClose = () => {
            ElMessageBox.confirm('确定不更改了吗？')
                .then(() => {
                    updateDrawer.value = false;
                    article_update_classify.value = '';
                    article_update_title.value = '';
                    article_update_content.value = '';
                    article_update_id.value = '';
                })
                .catch(() => {
                })
        }

        // 修改帖子
        const updateArticle = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/rewrite/article/",
                type: "post",
                data: {
                    article_id: article_update_id.value,
                    classify: article_update_classify.value,
                    title: article_update_title.value,
                    content: article_update_content.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    console.log(article_update_id.value);
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        update_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 修改成功提示
        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
            updateDrawer.value = false;
            myArticle();
            article_update_classify.value = '';
            article_update_title.value = "";
            article_update_content.value = "";
            article_update_id.value = "";
        }
        // 操作失败提示
        const error = () => {
            ElMessage.error(profile.error_message)
        }
        /*文章的修改~~end*/

        /*文章的删除~~start*/
        // 删除前弹出询问
        const confirmedDelete = (article_id, user_id) => {
            ElMessageBox.confirm('确定要删除此贴吗？')
                .then(() => {
                    deleteArticle(article_id, user_id);
                })
                .catch(() => {
                })
        }

        // 删除文章
        const deleteArticle = (article_id, user_id) => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/remove/article/",
                type: "post",
                data: {
                    id: article_id,
                    user_id: user_id
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        delete_success();
                    } else {
                        profile.error_message = resp.error_message;
                        error();
                    }
                }
            })
        }

        // 删除成功提示
        const delete_success = () => {
            ElMessage({
                message: '删除成功~',
                type: 'success',
            })
            myArticle();
        }
        /*文章的删除~~end*/

        /*点击量的增加~~start*/
        const jumpArticle = (id) => {
            article_id.value = id;
            addClick();
            router.push({
                name: "blog_article",
                path: "blog/article",
                query: {
                    id: id
                }
            })
        }
        const addClick = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/click/rate/",
                type: "post",
                data: {
                    article_id: article_id.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_click_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_click_error();
                    }
                },
                error() {

                }

            })
        }
        const add_click_success = () => {
            ElMessage({
                message: '~点击量+1~',
                type: 'success',
            })
            myArticle();
        }
        const add_click_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*点击量的增加~~end*/
        /**
         * 实现本地图片上传
         */
        const handleUploadImage = (event, insertImage, files) => {
            for (let i in files) {
                const formData = new FormData();
                formData.append('file', files[i]);
                console.log(files[i])
                $.ajax({
                    url: ` https://www.scqblog.com/api/article/`,
                    type: 'POST',
                    data: formData,
                    headers: {
                        Authorization: "Bearer " + store.state.user.token,
                    },
                    processData: false,
                    contentType: false,
                    success: response => {
                        insertImage({
                            url: response,
                            desc: files[i].name,
                        });
                    },
                    error: (xhr, status, error) => {
                        console.log('请求失败了', error);
                    },
                });
            }
        };
        return {
            store, addDrawer, addDrawer_up, addHandleClose, profile, classify, content, title,
            options, addArticle, add_article_success, add_article_error, save, save_load,
            myArticles, myArticle, jumpArticle, article_update_classify, article_update_title, updateDrawer,
            article_update_content, updateDrawer_up, updateHandleClose, update_success, updateArticle,
            error, article_update_id, confirmedDelete, deleteArticle, delete_success, add_click_success,
            add_click_error, addClick, article_id, text, total, currentPage, getPostTotal, changePostpage,
            handleUploadImage,
        }
    }

}
</script>

<style scoped>
.tac {
    float: left;
    width: 200px;
    position: relative;
    height: 92vh;
}

.add-news-text-field {
    background-color: #F0F2F5;
    cursor: pointer;
    height: 45px;
    border-radius: 50px;
    width: 50vh;
    font-size: 20px;
    color: #656768;
    padding-top: 7.5px;
}

.photo {
    float: left;
    width: 8vh;
    height: 8vh;
    margin-right: 7vh;
}

.photo_img {
    width: 8vh;
    height: 8vh;
    border-radius: 20%;
}

.big {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    /* 负值的间距，用于抵消卡片的间距 */
    padding-top: 10px;
    font-family: 阿里妈妈刀隶体 Regular;
}

.card-item {
    flex: 0 0 calc(33.3333% - 20px);
    /* 设置卡片的宽度，这里假设每行显示3个卡片 */
    margin: 10px;
    /* 设置卡片之间的间距 */
}


.hover-pointer {
    cursor: pointer;
    /* 设置鼠标悬浮时显示小手指示符 */
}

.card-header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-title {
    text-align: center;
}

.card-time {
    text-align: center;
    margin-top: 5px;
    font-size: 10px;
}

.card-dropdown {
    margin-top: 5px;
    text-align: right;

}

.title_center {
    text-align: center;
}
</style>
  