import { Chess } from "./Chess";
import { GameObject } from "./GameObject";
import { Wall } from "./Wall";
export class GameMap extends GameObject {
    constructor(ctx, parent, store) {
        super();

        this.ctx = ctx;
        this.parent = parent;

        this.L = 0;
        this.rows = 20;
        this.cols = 20;

        this.walls = [];

        this.store = store;
        //创建棋子
        this.chesses = [
            new Chess({ id: 0, color: "red" }, this),
            new Chess({ id: 1, color: "blue" }, this),
        ]

        // 添加鼠标点击事件监听器
        this.ctx.canvas.addEventListener("mousedown", this.handleMouseDown.bind(this));

        //存储点击后的坐标
        this.clickChess = [];

        //判断点的覆盖
        this.checkChess = false;
    }

    handleMouseDown(event) {
        const rect = this.ctx.canvas.getBoundingClientRect();
        const mouseX = event.clientX - rect.left;
        const mouseY = event.clientY - rect.top;

        const col = Math.floor(mouseX / this.L);
        const row = Math.floor(mouseY / this.L);

        const x = col * this.L;
        const y = row * this.L;

        //增强棋子的精确性
        const epsilon = 0.4;

        if (Math.floor(Math.abs(mouseX - x - this.L)) / this.L < epsilon && Math.floor(Math.abs(mouseY - y - this.L)) / this.L < epsilon) {
            this.store.state.wu.socket.send(JSON.stringify({
                event: "move",
                direction_x: row,
                direction_y: col,
            }));
        }
    }
    create_walls() {

        const g = this.store.state.wu.gamemap;

        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if (g[r][c]) {
                    this.walls.push(new Wall(r, c, this));//直接调用墙类
                }
            }
        }
    }

    start() {
        this.create_walls();
    }

    update_size() {
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));

        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    update() {
        this.update_size();
        this.render();
    }

    render() {
        const color_even = "#AAD751", color_odd = "#A2D149";
        for (let r = 0; r <= this.rows; r++) {
            for (let c = 0; c <= this.cols; c++) { // 在循环内部使用变量 c
                if (r < this.rows && c < this.cols) {
                    if ((r + c) % 2 == 0)
                        this.ctx.fillStyle = color_even;
                    else
                        this.ctx.fillStyle = color_odd;
                    this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
                }
            }
        }
    }
}