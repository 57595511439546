<template>
    <div v-if="!$store.state.user.pulling_info">
        <div class="bjimg"></div>
        <div class="my-login-box">
            <div class="image-container">
                <router-link :class="route_name == 'user_account_login' ? 'nav-link active' : 'nav-link'"
                    :to="{ name: 'user_account_login' }">
                    <img src="@/assets/images/会员登录.png" alt="" class="logo-img" style="width: 40px;height: 40px;">
                </router-link>
                <span class="separator"></span>
                <router-link :class="route_name == 'user_account_register' ? 'nav-link active' : 'nav-link'"
                    :to="{ name: 'user_account_register' }">
                    <img src="@/assets/images/退出.png" alt="" class="logout-img" style="width: 40px;height: 40px;">
                </router-link>
            </div>
            <form @submit.prevent="login">
                <div class="user-box">
                    <input v-model="username" type="text" class="form-control" id="username" placeholder="请输入用户名">
                </div>
                <div class="user-box">
                    <input v-model="password" type="password" class="form-control" id="password" placeholder="请输入密码">
                </div>
                <div class="error_message">{{ error_message }}</div>
                <div style="display: flex; justify-content: center; align-items: center;">
                    <div style="text-align: center;">
                        <button type="submit" class="btn btn-primary">登录</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="beian" style="margin-top: 80vh;text-align: center;">
        <a href="https://beian.miit.gov.cn/" target="_blank">您的备案号</a>
        <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023020657号-1</a>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref } from 'vue';
import router from '../../../router/index';

export default {
    components: {

    },
    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let error_message = ref('');

        const jwt_token = localStorage.getItem('jwt_token');
        if (jwt_token) {
            store.commit('updateToken', jwt_token);
            store.dispatch('getinfo', {
                success() {
                    // 在获取用户信息成功后，将路由导航到之前的页面
                    const previousRoute = localStorage.getItem('previousRoute');
                    if (previousRoute) {
                        router.push(previousRoute);
                        localStorage.removeItem('previousRoute'); // 导航到之前的页面后，删除之前保存的路由
                    } else {
                        router.push({ name: 'home' }); // 如果没有之前的页面，导航到主页
                    }
                    store.commit('updatePullingInfo', false);
                },
                error() {
                    store.commit('updatePullingInfo', false);
                }
            });
        } else {
            store.commit('updatePullingInfo', false);
        }
        // console.log(history.length)
        // console.log(history.state)

        // 在路由导航前保存当前路由
        router.beforeEach((to, from, next) => {
            if (to.path === '/user/account/login/' && store.state.user.is_login) {
                next(false);
            } else {
                // 否则允许路由导航
                localStorage.setItem('previousRoute', to.fullPath);
                next();
            }
        });
        const login = () => {
            error_message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    store.dispatch("getinfo", {
                        success() {
                            router.push({ name: 'home' });
                        }
                    });
                },
                error() {
                    error_message.value = "用户名或密码错误";
                },
            });
        }

        return {
            username,
            password,
            error_message,
            login,
        }
    }
}
</script>

<style scoped>
html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.separator {
    width: 1px;
    height: 40px;
    border-left: 6px solid #243b55;
    border-radius: 6px;
    background-color: #423e3e;
    margin: 0 10px;
}

.my-login-box {
    position: absolute;
    top: 63%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.my-login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
}

.my-login-box .user-box {
    position: relative;
}

.my-login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.my-login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.my-login-box .user-box input:focus~label,
.my-login-box .user-box input:valid~label {
    top: -30px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
}

.my-login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px
}

.my-login-box a:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.my-login-box a span {
    position: absolute;
    display: block;
}

.my-login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.my-login-box a span:nth-child(2) {
    top: -100%;
    right: 20px;
    left: 20px;

    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.my-login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.my-login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

.row {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.btn-primary {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(50, 50, 50, 0.7)
}

.col-3 {
    padding-top: 5vh;
    background-color: rgba(red, green, blue, alpha)
}

.form-control {
    background-color: rgba(50, 50, 50, 0.7)
}

.backendimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.bjimg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    z-index: -10;
    zoom: 1;
    background-color: #fff;
    background-image: url('../Joc/大头云开发\ \(5\).png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
</style>