<template>
    <div class="ul-container">
        <ul>
            <li>
                <div class="home"><router-link :class="route_name == 'home' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'home' }">
                        <img src="../assets/images/主页.png" alt="" style="width: 55px;height: 55px;">
                    </router-link></div>
                <div><router-link :class="route_name == 'home' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'home' }">主页</router-link></div>
            </li>
            <li>
                <div class="artical"><router-link :class="route_name == 'blog_view' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'blog_view' }">
                        <img src="../assets/images/写文章.png" alt="" style="width: 40px;height: 40px;">
                    </router-link></div>
                <div class="artical"><router-link :class="route_name == 'blog_view' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'blog_view' }">文章区</router-link></div>
            </li>
            <li>
                <div class="keep"><router-link :class="route_name == 'post_view' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'post_view' }">
                        <img src="../assets/images/留言.png" alt="" style="width: 50px;height: 50px;">
                    </router-link></div>
                <div><router-link :class="route_name == 'post_view' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'post_view' }">说说</router-link></div>
            </li>
            <li v-if="$store.state.user.id">
                <div>
                    <router-link :class="route_name == 'showinfo' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'showinfo' }">
                        <img src="../assets/images/个人中心.png" alt="" style="width: 40px;height: 40px;">
                    </router-link>
                </div>
                <div>
                    <router-link :class="route_name == 'user_account_login' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'user_account_login' }" @click="logout">
                        <img src="../assets/images/退出.png" alt="" style="width: 40px;height: 40px;"></router-link>
                </div>
            </li>
            <li v-else-if="!$store.state.user.id">
                <div>
                    <router-link :class="route_name == 'user_account_login' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'user_account_login' }">
                        <img src="../assets/images/登录1.png" alt="" style="width: 60px;height: 60px;padding-bottom: 8px;">
                    </router-link>
                </div>
                <div>
                    <router-link :class="route_name == 'user_account_login' ? 'nav-link active' : 'nav-link'"
                        :to="{ name: 'user_account_login' }">
                        登录
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import { useRoute } from 'vue-router'
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const route = useRoute();

        let route_name = computed(() => route.name);


        const logout = async () => {
            await store.dispatch("logout");
            store.commit("clean_articleadd");
            store.commit("clean_attentionadd");
            store.state.player.player1 = "";
            store.state.player.player2 = "";
        };

        return {
            route_name,
            logout,
        };
    }
}

</script>
<style scoped>
* {
    padding: 0;
    margin: 0;
}

body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

ul {
    /* 让li水平排列 */
    display: flex;
}

ul li {
    position: relative;
    /* 取消项目编号 就是小黑点 */
    list-style: none;
    width: 25%;
    height: 50px;
    /* 让鼠标变成小手 */
    cursor: pointer;
    /* 让子元素保留其3D位置 */
    transform-style: preserve-3d;
    transition: all 2.5s;
}

ul li:hover {
    transform: rotateX(90deg);
}

ul li:hover div:first-child {
    background-color: #51938f;
    background-size: 5px 5px;
    background-position: 30px 30px;
    background-image: linear-gradient(45deg,
            #478480 25%,
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 0) 75%,
            #478480 75%,
            #478480);
}

ul li div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    color: #000000;
    font-family: "仓耳渔阳体 W03";
}

.font {
    font-family: "仓耳渔阳体 W03";
}

/*导航栏字体美化--start*/
@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/fOPSCvYMNVqC.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/tAgMBnYghUb8.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/eqMEbAg6SxRC.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/I0f3s9wokYA4.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/6uDONWxVXuq8.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/NoOBWVc9f2Q6.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/glZ5V2kwPPnz.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/q4VQSAvx59sY.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/vmIImvHwh9vl.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/RRSs9ic1PQvO.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/vmIImvHwh9vl.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/RRSs9ic1PQvO.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "仓耳渔阳体 W03";
    font-weight: 400;
    src: url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/O6BybqGoKap5.woff2") format("woff2"),
        url("//at.alicdn.com/wf/webfont/uxHLo0RhRHZd/Q3hg29JL6BrT.woff") format("woff");
    font-display: swap;
}

/*导航栏字体美化--end*/
ul li div:first-child {
    background-color: #74adaa;
    transform: translateZ(25px);
}

ul li div:last-child {
    background-color: #51938f;
    background-size: 5px 5px;
    background-position: 30px 30px;
    background-image: linear-gradient(45deg,
            #478480 25%,
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 0) 75%,
            #478480 75%,
            #478480);
    /* 让第二个面往下移动25px 沿着X轴旋转-90度 */
    transform: translateY(25px) rotateX(-90deg);
}
</style>