<template>
  <NavBar />
  <router-view></router-view>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"
import NavBar from '@/components/NavBar.vue'
export default {
  components: {
    NavBar,
  },
  // setup: () => {
  //   $.ajax({
  //     url: " https://www.scqblog.com/user/account/token/",
  //     type: "post",
  //     data: {
  //       username: "3",
  //       password: "3",
  //     },
  //     success(resp) {
  //       console.log(resp);
  //     },
  //     error(resp) {
  //       console.log(resp);
  //     },
  //   });
  //   $.ajax({
  //     url: " https://www.scqblog.com/user/account/info/",
  //     type: "get",
  //     headers: {
  //       Authorization: "Bearer " + "eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJiNjczMDEzN2FkN2E0YmU2YTM4YzlkYjFiZWM4ZDI3YiIsInN1YiI6IjMiLCJpc3MiOiJzZyIsImlhdCI6MTY4OTQwOTM0NSwiZXhwIjoxNjkwNjE4OTQ1fQ.XspadAFeKtQM80GU4AEf5I70m8oPXL7U8Fxgs6efETM"
  //     },
  //     success(resp) {
  //       console.log(resp);
  //     },
  //     error(resp) {
  //       console.log(resp);
  //     },
  //   });
  //   $.ajax({
  //     url: " https://www.scqblog.com/user/account/register/",
  //     type: "post",
  //     data: {
  //       username: "123",
  //       password: "123",
  //       confirmedPassword: "123",
  //     },
  //     success(resp) {
  //       console.log(resp);
  //     },
  //     error(resp) {
  //       console.log(resp);
  //     },
  //   });
  // }
}
</script>
<style></style>
