<template>
    <MySpace class="sidebar"></MySpace>
    <div class="container" style="font-family: 阿里妈妈刀隶体 Regular; font-size: 30px;">
        <div class="row justify-content-center">
            <div class="card" style="margin-top: 2.5vh; width: 80vh;">
                <div class="card-body">
                    <div class="row" style="display: flex; justify-content: center;">
                        关注的博主文章
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-container">
        <div v-for="item in author" :key="item" class="card-item">
            <el-card class="hover-pointer">
                <div class="card-header">
                    <div class="card-title" @click="jumpArticle(item.id)">{{ item.title }}</div>
                    <div style="text-align: center;font-size: 10px;">{{ item.createTime }}</div>
                </div>
                <hr>
                <div style="text-align: center;font-size: 20px;">作者: {{ item.nickName }}</div>
                <div style="text-align: center;font-size: 20px;">点击量: {{ item.clickRate }}</div>
            </el-card>
        </div>
        <div class="page"
            style="position: fixed; bottom: 10vh; left: 50%; transform: translateX(-50%); background-color: white; padding: 10px;">
            <el-pagination v-model:current-page="currentPage" :page-size="6" :small="small" background
                layout="total, prev, pager, next" :total="total" @current-change="changePostpage" />
        </div>
    </div>
</template>
<script>
import MySpace from '../../../../components/MySpace.vue'
import { ref, reactive } from 'vue';
import $ from 'jquery';
import { useStore } from 'vuex';
import router from '@/router';
import { ElMessage } from 'element-plus';
export default {
    components: {
        MySpace,

    },
    setup() {
        let author = ref([])
        let article_id = ref('')
        const store = useStore()
        let total = ref('')
        let currentPage = ref(1)
        const profile = reactive({
            error_message: '',
        })
        const author_info = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/attention/article/",
                type: "post",
                data: {
                    page: 1,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    author.value = resp;
                    currentPage.value = 1;
                }
            })
        }
        author_info();
        const getPostTotal = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/get/attention/article/page/",
                type: "post",
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    total.value = resp;
                },
            })
        }
        getPostTotal();

        const changePostpage = (number) => {
            $.ajax({
                url: " https://www.scqblog.com/api/attention/article/",
                type: "post",
                data: {
                    page: number,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    author.value = resp;
                }
            })
        }
        // 打开原文页面
        const jumpArticle = (id) => {
            article_id.value = id;
            addClick();
            router.push({
                name: "blog_article",
                path: "blog/article",
                query: {
                    id: id
                }
            })
        }
        /*点击量的增加~~start*/
        const addClick = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/click/rate/",
                type: "post",
                data: {
                    article_id: article_id.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_click_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_click_error();
                    }
                },
                error() {

                }

            })
        }
        const add_click_success = () => {
            ElMessage({
                message: '~点击量+1~',
                type: 'success',
            })
        }
        const add_click_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*点击量的增加~~end*/

        return {
            author, author_info, store, jumpArticle, article_id, addClick, add_click_success,
            add_click_error, profile, changePostpage, getPostTotal, total, currentPage
        }
    }
}
</script>
<style scoped>
.card-container {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    /* 负值的间距，用于抵消卡片的间距 */
    padding-top: 10px;
    font-family: 阿里妈妈刀隶体 Regular;
}

.card-item {
    flex: 0 0 calc(33.3333% - 20px);
    /* 设置卡片的宽度，这里假设每行显示3个卡片 */
    margin: 10px;
    /* 设置卡片之间的间距 */
}

.add-news-text-field {
    background-color: #F0F2F5;
    cursor: pointer;
    height: 45px;
    border-radius: 50px;
    padding: 10px 10px 10px 15px;
    font-size: 20px;
    color: #656768;
}

.hover-pointer {
    cursor: pointer;
    /* 设置鼠标悬浮时显示小手指示符 */
}

.card-header {
    text-align: center;
    font-size: 20px;
}

.card-title {
    text-align: center;
    font-size: 25px;
}

.title_center {
    text-align: center;
    font-size: 60px;
    font-family: 阿里妈妈刀隶体 Regular;
}
</style>