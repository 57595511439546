import { GameObject } from "./GameObject";

export class Chess extends GameObject {
    constructor(info, gamemap) {
        super();

        this.id = info.id;
        this.color = info.color;
        this.gamemap = gamemap;

        this.chesses = [];


        this.direction_x = null;
        this.direction_y = null;
    }

    set_direction(x, y, id) {
        this.direction_x = x;
        this.direction_y = y;
        this.chesses.push({ x: this.direction_x, y: this.direction_y, id: id });
        this.direction_x = null;
        this.direction_y = null
    }

    start() {

    }

    update() {
        this.render();
    }

    render() {
        const L = this.gamemap.L;
        const ctx = this.gamemap.ctx;

        for (const cell of this.chesses) {
            if (cell.id === 0) {
                ctx.fillStyle = "black";
            } else {
                ctx.fillStyle = "white";
            }

            ctx.beginPath();
            ctx.arc((cell.y + 1) * L, (cell.x + 1) * L, L / 4, 0, Math.PI * 2);
            ctx.fill();
        }
    }
}