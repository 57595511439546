<template>
    <div class="head">
        <el-row class="title">
            <el-col :span="2">
            </el-col>
            <el-col :span="4">
                <div>
                    <img :src="data_user.photo" alt="头像" class="img-photo">
                </div>
            </el-col>
            <el-col :span="12">
                <div class="container_name">
                    <div class="username">
                        {{ data_user.nickname }}
                    </div>
                    <div class="page_view">
                        <span class="page_view_span_number">
                            {{ page_view }}
                        </span>
                        <span class="page_view_span_fonts">
                            访问量
                        </span>
                        <el-divider direction="vertical" />
                        <span class="create_article_number">
                            {{ create_article_numbers }}
                        </span>
                        <span class="create_article_fonts">
                            原创
                        </span>
                        <el-divider direction="vertical" />
                        <span class="fens_number">
                            {{ fans_number }}
                        </span>
                        <span class="fens_fonts">
                            粉丝
                        </span>
                    </div>
                    <div class="introduction">
                        <span class="introduction_fonts">个人介绍：{{ data_user.introduction }}</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="6">
                <span v-if="data_user.username === $store.state.user.username"
                    style="font-size: 15px; color: gray;">作者竟是我自己</span>
                <el-button v-else type="primary">
                    <span v-if="attention.status === 'true'" @click="removeAttention()">已关注</span>
                    <span v-else @click="addAttention()">关注</span>
                </el-button>
            </el-col>
        </el-row>
    </div>
    <div class="body">
        <el-row class="container-1">
            <el-col :span="24">
                <el-menu active-text-color="#ffd04b" background-color="#0fc0c01a">
                    <el-menu-item index="1">
                        <span class="information" @click="information_user()">信息介绍</span>
                    </el-menu-item>
                    <el-menu-item index="2">
                        <span class="article" @click="information_article()">精选文章</span>
                    </el-menu-item>
                    <el-menu-item index="3">
                        <span class="comment" @click="information_comment()">私信评论</span>
                    </el-menu-item>
                </el-menu>

            </el-col>
        </el-row>
        <el-row v-if="navbar === 1">
            <el-col :span="24">
                <div class="container">
                    <div class="container_information_title">基本信息</div>
                    <el-divider>
                        <el-icon><star-filled /></el-icon>
                    </el-divider>
                    <el-row>
                        <el-col :span="6"></el-col>
                        <el-col :span="18">
                            <div class="usernames">账号：{{ data_user.username }}</div>
                            <div class="nickname">昵称：{{ data_user.nickname }}</div>
                            <div class="introductions">签名：{{ data_user.introduction }}</div>
                            <div class="email">邮箱：{{ data_user.email }}</div>
                            <div class="phone">手机号：{{ data_user.phone }}</div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <el-row v-if="navbar === 2">
            <el-col :span="24">
                <div class="container">
                    <div v-if="article.length > 0">
                        <div style="padding-top: 10px;">
                            <el-alert title="点击文章标题可以直达哦" type="success" :closable="false" center />
                        </div>
                        <div class="card-container">
                            <div v-for="item in article" :key="item" class="card-item" style="padding-top: 2vh;">
                                <el-card style="font-size: 20px;padding-bottom: 2vh;" class="hover-pointer">
                                    <div class="card-header">
                                        <div class="card-title" @click="jumpArticle(item.id)"
                                            style="float: left;padding-right: 10px;cursor: pointer;">文章：{{
                                                item.title }}</div>
                                        <div class="click-rate" style="float: left;padding-right: 10px;">点击量: {{
                                            item.clickRate }}</div>
                                        <div class="card-time" style="float: left;">创作时间：{{ item.createTime }}</div>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        该博主还未有优质好文
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row v-if="navbar === 3">
            <el-col :span="24">
                <div class="container">未完待继</div>
            </el-col>
        </el-row>
    </div>
</template>
  

  
<script>
import { useStore } from 'vuex';
import { ref, reactive } from 'vue';
import { useRoute } from 'vue-router'
import $ from 'jquery'
import { ElMessage } from 'element-plus';
import { StarFilled } from '@element-plus/icons-vue'
import router from '@/router';
export default {
    components: {
        StarFilled
    },
    setup() {
        const store = useStore();
        const route = useRoute()
        const data_user = ref([]);
        const article = ref([]);
        let article_id = ref('')
        let post_nickname = route.query.username;
        let page_view = ref('')
        let create_article_numbers = ref('')
        let fans_number = ref('')
        let statu = ref('')
        let attention = ref([])
        let navbar = ref('');
        const profile = reactive({
            error_message: '',
        })
        navbar.value = parseInt(1);
        //获取帖子用户的个人信息~~start
        const getUserInformation = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/user/get/information/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    data_user.value = resp;
                    console.log(post_nickname)
                    // console.log(data_user.value.id)
                    // console.log(store.state.user.id)
                }
            })
        }
        getUserInformation();
        //获取帖子用户的个人信息~~end

        //获取访问记录~~start
        const pageView = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/information/page/view/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    page_view.value = resp;
                }
            })
        }
        pageView();
        //获取访问记录~~end

        //获取文章数据~~start
        const create_article = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/information/create/article/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    create_article_numbers.value = resp;
                }
            })
        }
        create_article();
        //获取文章数据~~end

        //获取粉丝数据~~start
        const fansNumber = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/information/fans/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    fans_number.value = resp;
                }
            })
        }
        fansNumber();
        //获取粉丝数据~~end

        /*关注~~start*/
        const addAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/add/attention/",
                type: "post",
                data: {
                    author_name: post_nickname,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_attention_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_attention_error();
                    }
                }
            })
        }
        // 成功提示
        const add_attention_success = () => {
            ElMessage({
                message: '关注成功~',
                type: 'success',
            })
            statusAttention();
            fansNumber();
        }

        // 失败提示
        const add_attention_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*关注~~end*/

        /*关注状态~~start*/
        const statusAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/get/one/attention/impl/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    attention.value = resp;
                }
            })
        }
        statusAttention();
        statu.value = attention.value.status;
        /*关注状态~~end*/

        /**取消关注~~start*/
        const removeAttention = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/remove/attention/",
                type: "post",
                data: {
                    author_name: post_nickname,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        remove_attention_success();
                    } else {
                        profile.error_message = resp.error_message;
                        remove_attention_error();
                    }
                }
            })
        }
        // 成功提示
        const remove_attention_success = () => {
            ElMessage({
                message: '取消关注成功~',
                type: 'success',
            })
            statusAttention();
            fansNumber();
        }

        // 失败提示
        const remove_attention_error = () => {
            ElMessage.error(profile.error_message)
        }
        /**取消关注~~end*/

        /**导航栏内容切换~~start*/
        const information_user = () => {
            navbar.value = parseInt(1);
        }
        const information_article = () => {
            navbar.value = parseInt(2);
        }
        const information_comment = () => {
            navbar.value = parseInt(3);
        }
        /**导航栏内容切换~~end*/
        const refresh_articles = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/get/good/article/",
                type: "post",
                data: {
                    nickname: post_nickname
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    article.value = resp;
                },
                error() {
                }
            })
        }

        refresh_articles();
        // 打开原文页面
        const jumpArticle = (id) => {
            article_id.value = id;
            addClick();
            router.push({
                name: "blog_article",
                path: "blog/article",
                query: {
                    id: id
                }
            })
        }
        /*点击量的增加~~start*/
        const addClick = () => {
            profile.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/click/rate/",
                type: "post",
                data: {
                    article_id: article_id.value,
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_message = resp.error_message;
                        add_click_success();
                    } else {
                        profile.error_message = resp.error_message;
                        add_click_error();
                    }
                },
                error() {

                }

            })
        }
        const add_click_success = () => {
            ElMessage({
                message: '~点击量+1~',
                type: 'success',
            })
            refresh_articles();
        }
        const add_click_error = () => {
            ElMessage.error(profile.error_message)
        }
        /*点击量的增加~~end*/
        return {
            data_user, post_nickname, route, getUserInformation, page_view, pageView, create_article_numbers,
            create_article, fansNumber, fans_number, profile, remove_attention_error, remove_attention_success,
            statusAttention, removeAttention, statu, add_attention_success, add_attention_error, addAttention,
            attention, navbar, information_user, information_article, information_comment, article, article_id,
            jumpArticle, addClick, add_click_success, add_click_error,
        }
    }
}
</script>  

<style scoped>
@font-face {
    font-family: 阿里妈妈刀隶体 Regular;
    src: url('../../assets/font/AlimamaDaoLiTi.ttf');
}

.card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.phone {
    font-size: 25px;

}

.email {
    font-size: 25px;

}

.introductions {
    font-size: 25px;

}

.nickname {
    font-size: 25px;

}

.usernames {
    font-size: 25px;

}

.container_information_title {
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 35px;
}

.comment {
    height: 8vh;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.article {
    height: 8vh;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

.information {
    height: 8vh;
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    user-select: none;
}

.body {
    padding-top: 2vh;
    padding-bottom: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 阿里妈妈刀隶体 Regular;
}

.navbar {
    float: none;
}

.container-1 {
    width: 20vh;
    height: 62vh;
    background-color: rgba(15, 192, 192, 0.102);
}

.container {
    width: 100vh;
    height: 62vh;
    background-color: rgba(15, 192, 192, 0.1);
    margin-left: 1vh;
    border-radius: 5%;
    user-select: none;
}

.fens_number {
    font-size: 20px;
    font-weight: 800;
}

.create_article_number {
    font-size: 20px;
    font-weight: 800;
}

.page_view_span_number {
    font-size: 20px;
    font-weight: 800;
}

.introduction {
    padding-top: 10px;
}

.page_view {
    padding-top: 10px;
}

.container_name {
    width: 100%;
    height: 15vh;
}

.username {
    font-size: 25px;
}

.img-photo {
    width: 15vh;
    height: 15vh;
    border-radius: 50%;
}

.head {
    padding-top: 2vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 阿里妈妈刀隶体 Regular;
}

.title {
    width: 120vh;
    height: 20vh;
    background-color: rgba(0, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
}
</style>