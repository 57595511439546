<template>
    <div ref="parent" class="gamemap">
        <canvas ref="canvas"></canvas>
    </div>
</template>

<script>
import { GameMap } from '../script/GameMap';
import { ref, onMounted } from 'vue';

export default {
    setup() {
        let parent = ref(null);
        let canvas = ref(null);

        /**
         * 创建一个新的画布，这就是canvas的语法了
         */
        onMounted(() => {
            new GameMap(canvas.value.getContext('2d'), parent.value);
        })

        return {
            parent,
            canvas
        }
    }
}
</script>
<style scoped>
div.gamemap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>