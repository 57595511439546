<template>
    <div class="bjimg"></div>
    <div class="my-login-box">
        <div class="image-container">
            <router-link :class="route_name == 'user_account_login' ? 'nav-link active' : 'nav-link'"
                :to="{ name: 'user_account_login' }">
                <img src="@/assets/images/会员登录.png" alt="" class="logo-img" style="width: 40px;height: 40px;">
            </router-link>
            <span class="separator"></span>
            <router-link :class="route_name == 'user_account_register' ? 'nav-link active' : 'nav-link'"
                :to="{ name: 'user_account_register' }">
                <img src="@/assets/images/退出.png" alt="" class="logout-img" style="width: 40px;height: 40px;">
            </router-link>
        </div>
        <form @submit.prevent="register">
            <div class="user-box">
                <label for="username" class="form-label"></label>
                <input v-model="username" type="text" class="form-control" style="margin-bottom: 10px;" id="username"
                    placeholder="请输入用户名">
            </div>
            <div class="user-box">
                <label for="password" class="form-label"></label>
                <input v-model="password" type="text" class="form-control" id="password" style="margin-bottom: 10px;"
                    placeholder="请输入密码">
            </div>
            <div class="user-box">
                <label for="confirmedPassword" class="form-label"></label>
                <input v-model="confirmedPassword" type="password" class="form-control" id="confirmedPassword"
                    placeholder="请再次输入密码" autocomplete="current-password" style="margin-bottom: 10px;">
            </div>
            <div class="user-box center-align">
                <label for="confirmedPassword" class="form-label"></label>
                <input v-model="email" type="email" class="form-control" id="email" placeholder="请输入邮箱"
                    autocomplete="current-password" style="margin-bottom: 10px;">
                <button type="button" class="btn btn-outline-primary" @click="sendEmail($event)"
                    style="height: 55px;">发送</button>
            </div>
            <div class="user-box">
                <label for="code" class="form-label"></label>
                <input v-model="code" type="code" class="form-control" id="code" placeholder="请输入验证码">
            </div>
            <div class="error_message">{{ error_message }}</div>
            <a style="display: flex;justify-content: center;align-items: center;width: 300px;margin-top: 5px;">
                <div style="text-align: center;">
                    <button type="submit" class="btn btn-primary">注册</button>
                </div>
            </a>
        </form>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import $ from 'jquery'
import router from '../../../router';
import { ElMessage } from 'element-plus';
export default {
    components: {

    },
    setup() {
        let username = ref('');
        let password = ref('');
        let confirmedPassword = ref('');
        let error_message = ref('');
        let email = ref('')
        let code = ref('')
        const profile = reactive({
            error_messages: '',
        })
        const register = () => {
            $.ajax({
                url: " https://www.scqblog.com/api/user/account/register/",
                type: "post",
                data: {
                    username: username.value,
                    password: password.value,
                    confirmedPassword: confirmedPassword.value,
                    code: code.value,
                    email: email.value
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        router.push({ name: "user_account_login" });//跳转页面
                    } else {
                        error_message.value = resp.error_message;
                    }
                },
            });
        }
        const sendEmail = (event) => {
            profile.error_messages = "";
            event.preventDefault(); // 阻止表单的默认提交行为
            $.ajax({
                url: " https://www.scqblog.com/api/email/send/",
                type: "get",
                data: {
                    email: email.value
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profile.error_messages = resp.error_message;
                        send_success();
                    } else {
                        profile.error_messages = resp.error_message;
                        send_error();
                    }
                },
            });
        }
        const send_success = () => {
            ElMessage({
                message: '~邮件发送成功~',
                type: 'success',
            })
        }
        const send_error = () => {
            ElMessage.error(profile.error_messages)
        }
        return {
            username,
            password,
            confirmedPassword,
            error_message, send_success, send_error,
            register, email, sendEmail, code, profile
        }
    }
}
</script>

<style scoped>
html {
    height: 100%;
}

.center-align {
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: linear-gradient(#141e30, #243b55);
}

.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.separator {
    width: 1px;
    height: 40px;
    border-left: 6px solid #243b55;
    border-radius: 6px;
    background-color: #423e3e;
    margin: 0 10px;
}

.my-login-box {
    position: absolute;
    top: 63%;
    left: 50%;
    width: 400px;
    padding: 40px;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, .3);
    box-sizing: border-box;
    box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.my-login-box h2 {
    margin: 0 0 30px;
    padding: 0;
    color: #fff;
    text-align: center;
}

.my-login-box .user-box {
    position: relative;
}

.my-login-box .user-box input {
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
}

.my-login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 16px;
    color: #fff;
    pointer-events: none;
    transition: .5s;
}

.my-login-box .user-box input:focus~label,
.my-login-box .user-box input:valid~label {
    top: -30px;
    left: 0;
    color: #03e9f4;
    font-size: 12px;
}

.my-login-box form a {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: #03e9f4;
    font-size: 16px;
    text-decoration: none;
    text-transform: uppercase;
    overflow: hidden;
    transition: .5s;
    margin-top: 40px;
    letter-spacing: 4px
}

.my-login-box a:hover {
    background: #03e9f4;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4;
}

.my-login-box a span {
    position: absolute;
    display: block;
}

.my-login-box a span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
    0% {
        left: -100%;
    }

    50%,
    100% {
        left: 100%;
    }
}

.my-login-box a span:nth-child(2) {
    top: -100%;
    right: 20px;
    left: 20px;

    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    animation: btn-anim2 1s linear infinite;
    animation-delay: .25s
}

@keyframes btn-anim2 {
    0% {
        top: -100%;
    }

    50%,
    100% {
        top: 100%;
    }
}

.my-login-box a span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    animation: btn-anim3 1s linear infinite;
    animation-delay: .5s
}

@keyframes btn-anim3 {
    0% {
        right: -100%;
    }

    50%,
    100% {
        right: 100%;
    }
}

.my-login-box a span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    animation: btn-anim4 1s linear infinite;
    animation-delay: .75s
}

@keyframes btn-anim4 {
    0% {
        bottom: -100%;
    }

    50%,
    100% {
        bottom: 100%;
    }
}

.row {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.btn-primary {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(50, 50, 50, 0.7)
}

.col-3 {
    padding-top: 5vh;
    background-color: rgba(red, green, blue, alpha)
}

.form-control {
    background-color: rgba(50, 50, 50, 0.7)
}

.backendimg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.bjimg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    z-index: -10;
    zoom: 1;
    background-color: #fff;
    background-image: url('../Joc//大头云开发\ \(5\).png');
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-position: center 0;
}
</style>