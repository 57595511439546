<template>
    <MySpace class="sidebar"></MySpace>
    <div style="display: flex;justify-content: center;align-items: center;">
        <div class="card" style="font-family: 阿里妈妈刀隶体 Regular; width: 100vh;height: 60vh;">
            <div class="card-body">
                <div class="row" style="margin-left: 20px; margin-right: 20px;">
                    <h3 style="margin-top: 20px; margin-bottom: 20px;display: flex;
justify-content: center;
    align-items: center;font-size: 40px;">账号安全</h3>
                    <hr>
                    <div class="row" style="margin-top: 15px; margin-bottom: 31px;">
                        <div class="col-4">
                            <span style="font-weight: 600;font-size: 25px;">账号</span>
                        </div>
                        <div class="col-4" style="font-size: 20px;">
                            <span>{{ username }}</span>
                        </div>
                        <div class="col-4" style="text-align: right;font-size: 25px;">
                            <span>无法修改</span>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="margin-top: 15px; margin-bottom: 31px;">
                        <div class="col-4">
                            <span style="font-weight: 600;font-size: 25px;">密码</span>
                        </div>
                        <div class="col-4" style="font-size: 20px;">
                            <span>已设置</span>
                        </div>
                        <div class="col-4" style="text-align: right;font-size: 25px;">
                            <a href="#" @click="changePasswordOpenView(true)">修改密码</a>
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
    <el-dialog v-model="changePasswordVisible" title="修改密码" width="20%" center align-center>
        <div class="content_center" style="text-align: center; margin-bottom: 20px;">
            <el-input v-model="password" type="password" show-password placeholder="请输入原密码" style="width: 80%;" />
        </div>
        <div class="content_center" style="text-align: center; margin-bottom: 20px;">
            <el-input v-model="new_password" type="password" show-password placeholder="请输入新密码" style="width: 80%;" />
        </div>
        <div class="content_center" style="text-align: center;">
            <el-input v-model="confirmedPassword" type="password" show-password placeholder="再次确认新密码" style="width: 80%;" />
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="changePasswordOpenView(false)">取消</el-button>
                <el-button type="primary" @click="updatePassword">
                    确认
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import MySpace from '../../../components/MySpace.vue'
import { useStore } from 'vuex';
import { reactive, ref } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import $ from 'jquery';
import router from '@/router';
export default {
    components: {
        MySpace
    },
    setup() {
        const store = useStore()

        let username = ref('')
        let changeUsernameVisible = ref(false)
        let changePasswordVisible = ref(false)
        let new_username = ref('')
        let password = ref('')
        let new_password = ref('')
        let confirmedPassword = ref('')
        const getinfo = () => {
            username.value = store.state.user.username;
        }

        getinfo();
        const profileUpdate = reactive({
            error_message: '',
        })
        const updatePassword = () => {
            profileUpdate.error_message = "";
            $.ajax({
                url: " https://www.scqblog.com/api/user/account/update/password/",
                type: "post",
                data: {
                    password: password.value,
                    new_password: new_password.value,
                    confirmedPassword: confirmedPassword.value
                },
                headers: {
                    Authorization: "Bearer " + store.state.user.token,
                },
                success(resp) {
                    if (resp.error_message === "success") {
                        profileUpdate.error_message = resp.error_message;
                        store.dispatch("getinfo", {
                            success() {
                                open();
                            },
                            error() {
                            }
                        })
                        update_success();
                    } else {
                        profileUpdate.error_message = resp.error_message;
                        update_error();
                    }
                },
                error() {
                    profileUpdate.error_message = "密码错误!";
                    update_error();
                }
            })
        }

        // 更新账号


        const update_success = () => {
            ElMessage({
                message: '修改成功~',
                type: 'success',
            })
        }
        const update_error = () => {
            ElMessage.error(profileUpdate.error_message)
        }

        // 确认框
        const open = () => {
            ElMessageBox.confirm(
                '您需要重新登录以进行正常体验',
                '修改成功!',
                {
                    confirmButtonText: '确认',
                    type: 'info',
                }
            )
                .then(() => {
                    store.dispatch("logout");
                    router.push({ name: "user_account_login" });
                })
                .catch(() => {
                    store.dispatch("logout");
                    router.push({ name: "user_account_login" });
                })
        }


        // 打开或关闭修改账号传输框
        const changeUsernameOpenView = (keyword) => {
            changeUsernameVisible.value = keyword
        }

        // 打开或关闭修改密码传输框
        const changePasswordOpenView = (keyword) => {
            changePasswordVisible.value = keyword
        }

        return {
            store,
            username,
            profileUpdate,
            changeUsernameVisible,
            changePasswordVisible,
            new_username,
            password,
            new_password,
            confirmedPassword,
            getinfo,

            updatePassword,
            update_success,
            update_error,
            open,
            changeUsernameOpenView,
            changePasswordOpenView,
        }

    }
}
</script>
<style>
.card {
    margin-top: 16vh;
}
</style>