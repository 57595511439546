export default {
    state: {
        id: "",
        user_id: "",
        classify: "",
        title: "",
        content: "",
        create_time: "",
        modify_time: "",
        click_rate: "",
        nick_name: "",
    },
    getters: {
    },
    mutations: {
        // 清除缓存
        clean_articleadd() {
            localStorage.removeItem("id");
            localStorage.removeItem("user_id");
            localStorage.removeItem("classify");
            localStorage.removeItem("title");
            localStorage.removeItem("content");
            localStorage.removeItem("create_time");
            localStorage.removeItem("modify_time");
            localStorage.removeItem("click_rate");
            localStorage.removeItem("nick_name");
        },

        // 同步更新state书本信息
        updateArticle(state, post) {
            state.id = post.id;
            state.user_id = post.user_id;
            state.classify = post.classify;
            state.title = post.title;
            state.content = post.content;
            state.create_time = post.create_time;
            state.modify_time = post.modify_time;
            state.click_rate = post.click_rate;
            state.nick_name = post.nick_name;
        },
    },
    actions: {
    },
    modules: {
    }
}