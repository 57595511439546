<template>
  <body>
    <div class="home">
      <div class="beian">
        <a href="https://beian.miit.gov.cn/" target="_blank">您的备案号</a>
        <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023020657号-1</a>
      </div>
      <div style="text-align: center;" class="title">博客欢迎你</div>
      <div class="icons">
        <div class="icons_items">
          <img class="icons_items_imgs" src="@/assets/homeimg/游戏机.png" alt="">
          <span>游戏源码</span>
        </div>
        <div class="icons_items" @click="jump_insert_study()">
          <img class="icons_items_imgs" src="@/assets/homeimg/努力学习.png" alt="">
          <span>自律学习</span>
        </div>
        <div class="icons_items">
          <img class="icons_items_imgs" src="@/assets/images/说说.png" alt="">
          <span>聊天测试</span>
        </div>
        <div class="icons_items" @click="jump_insert_wuziqi()">
          <img class="icons_items_imgs" src="@/assets/homeimg/wzq.png" alt="">
          <span>五子测试</span>
        </div>
      </div>
      <div class="introduce">
        <div class="introduce_item">
          <img class="introduce_item_img" src="@/assets/homeimg/天空.jpg" alt="">
          <div class="introduce_item_title">红霞满天秋色好，斜阳夕照白云高</div>
        </div>
        <div class="item_title">红霞满天秋色好，斜阳夕照白云高</div>
        <div class="introduce_item">
          <div class="introduce_item_titles">晨曦透洒进教室，纸上笔武青春</div>
          <img class="introduce_item_img" src="@/assets/homeimg/教室.jpg" alt="">
        </div>
        <div class="item_title">晨曦透洒进教室，纸上笔武青春</div>
        <div class="introduce_item">
          <img class="introduce_item_img" src="@/assets/homeimg/操场.jpg" alt="">
          <div class="introduce_item_title">
            儿童散学归来早，忙趁东风放纸鸢
          </div>
        </div>
        <div class="item_title">
          儿童散学归来早，忙趁东风放纸鸢
        </div>
        <div class="introduce_item_bottom">
          <div class="introduce_item_titles">
            醉后不知天在水，满船清梦压星河</div>
          <img class="introduce_item_img" src="@/assets/homeimg/晚自习.jpg" alt="">
        </div>
        <div class="item_title_bottom">醉后不知天在水，满船清梦压星河</div>
      </div>
    </div>
  </body>
</template>
<script>
import router from '@/router';

export default {
  setup() {
    const jump_insert_game = () => {
      router.push({ name: "game_view" });
    }
    const jump_insert_study = () => {
      router.push({ name: "study_home" });
    }
    const jump_insert_wuziqi = () => {
      router.push({ name: "wu_home" });
    }
    return {
      jump_insert_game,
      jump_insert_study,
      jump_insert_wuziqi
    };
  }
}
</script>
<style scoped>
@font-face {
  font-family: "阿里妈妈数黑体 Bold";
  src: url('../assets/fonts/AlimamaShuHeiTi-Bold.ttf');
}

* {
  margin: 0;
  padding: 0
}


.home {
  height: 100%;
  overflow: hidden;
}

.content {
  margin-top: 10px;
  perspective: 340px;
  width: 100%;
  position: relative;
}

h1 {
  color: white;
  text-align: center;
}

span {
  display: block;
  text-align: center;
}

@keyframes fly {
  from {
    transform: translateZ(0px);
    opacity: .6;
  }

  to {
    transform: translateZ(200px);
    opacity: .8;
  }
}

/**
  flex-direction: column;
  指定弹性容器中项目的排列方向。它指定项目按照垂直方向（从上到下）依次排列
*/
.introduce {
  display: flex;
  flex-direction: column;
}

.introduce_item {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.introduce_item_img {
  width: 800px;
  height: 500px;
  border-radius: 10%;
}

.introduce_item_title {
  width: 300px;
  font-size: 18px;
  line-height: 36px;
  align-self: center;
  padding-left: 100px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* 标准语法 */
  font-family: "阿里妈妈数黑体 Bold" !important;
}

.introduce_item_titles {
  width: 300px;
  font-size: 18px;
  line-height: 36px;
  align-self: center;
  padding-right: 100px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* 标准语法 */
  font-family: "阿里妈妈数黑体 Bold";
}

audio {
  position: fixed;
  bottom: 50px;
  right: 0;
}

.music_img {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 40px;
  height: 40px;
  animation: muscis 5s linear infinite;
}

.icons {
  display: flex;
  justify-content: space-between;
  padding: 0 200px;
  box-sizing: border-box;
  overflow: hidden;
}

.icons_items {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-top: 80px;
  width: 200px;
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* 标准语法 */
  font-family: "阿里妈妈数黑体 Bold";
  color: #43b881;
  user-select: none;
  /* 禁止文字选中 */
  cursor: pointer;
  /* 将鼠标指针设置为小手指光标 */
}

.icons_items_imgs {
  height: 150px;
  width: 200px;
  margin-bottom: 30px;
  border-radius: 10%;
}

.beian {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  background-color: white;
  padding: 10px 0;
  font-size: 14px;
  font-family: "阿里妈妈数黑体 Bold", sans-serif;
  color: #666;
  z-index: 9999;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 40px;
    margin-top: 30px;
    text-align: center;
  }

  .icons {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .icons_items {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-top: 20px;
    width: 50px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold";
    color: #43b881;
    user-select: none;
    /* 禁止文字选中 */
    cursor: pointer;
    /* 将鼠标指针设置为小手指光标 */
  }

  .icons_items_imgs {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
    border-radius: 10%;
  }

  .introduce_item,
  .introduce_item_bottom {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
  }

  .introduce_item_img {
    width: 300px;
    height: 200px;
    border-radius: 10%;
  }

  .introduce_item_title {
    display: none;
  }

  .introduce_item_titles {
    display: none;
  }

  .item_title {
    width: 100%;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold" !important;
  }

  .item_title_bottom {
    width: 100%;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold" !important;
  }
}

@media screen and (min-width: 500px) {
  .title {
    font-size: 40px;
    margin-top: 30px;
    text-align: center;
  }

  .icons {
    display: flex;
    justify-content: space-between;
    margin: 0px calc(10%);
    padding: 0 calc(5% + 10px);
    box-sizing: border-box;
    overflow: hidden;
  }

  .icons_items {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    margin-top: 20px;
    width: 80px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold";
    color: #43b881;
    user-select: none;
    /* 禁止文字选中 */
    cursor: pointer;
    /* 将鼠标指针设置为小手指光标 */
  }

  .icons_items_imgs {
    height: 80px;
    width: 80px;
    margin-bottom: 10px;
    border-radius: 10%;
  }

  .item_title {
    display: none;
  }

  .item_title_bottom {
    display: none;
  }

  .introduce_item_img {
    width: calc(40%);
    height: calc(40%);
    border-radius: 10%;
  }

  .introduce_item_title {
    width: 350px;
    font-size: 18px;
    line-height: 36px;
    align-self: center;
    padding-left: 50px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold" !important;
  }

  .introduce_item_titles {
    width: 350px;
    font-size: 18px;
    line-height: 36px;
    align-self: center;
    padding-right: 50px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* 标准语法 */
    font-family: "阿里妈妈数黑体 Bold";
  }

  .introduce_item,
  .introduce_item_bottom {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .introduce_item_bottom {
    margin-bottom: 60px;
  }
}
</style>