export default {
    state: {
        player1: "",
        player2: "",
    },
    getters: {
    },
    mutations: {
        // 清除缓存
        clean_player() {
            localStorage.removeItem("player1");
            localStorage.removeItem("player2");
        },
        updatePlayer(state, player) {
            state.player1 = player.player1;
            state.player2 = player.player2;
        },
    },
    actions: {
    },
    modules: {
    }
}